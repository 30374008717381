import React from 'react';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import db from './Edicao2_db';

export default function Edicao2() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Revista Acadêmica - 2º Edição</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div className="file-card-container">
          {db.map((el) => (
            <div className="file-card" key={el.title}>
              <h1 className="file-card-title center-text">
                {el.title} </h1>
              <div className="file-card-divison"></div>
              <a href={el.url}
                className="file-card-link">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
                  className="pdf-img"
                />
                <p>Abrir o PDF</p>
              </a>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
