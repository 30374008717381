import React from 'react';
import './Pdi.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Pdi() {
  return (
    <>
      <Header/>
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>PDI - Plano de Desenvolvimento Institucional</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div className='file-card-container single-card'>
          <div className='file-card'>
            <h1
              className='file-card-title center-text'
            > PDI FACISA </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/pdi.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
