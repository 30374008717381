import React from 'react';
import './Cpa.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Cpa() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>CPA</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <img
          src="/assets/images/cpa.jpg"
          className="cpa-image"
        />
      </div>

      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1> O que é uma CPA? </h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <img
          src="/assets/images/cpa2.jpg"
          className="cpa-image"
        />
      </div>


      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Links Importantes</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div className='file-card-container'>

          <div className='file-card'>
            <h1 className='file-card-title'> Regulamento CPA </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/cpa_regulamento.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> Composição/Portaria 002/2024 </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/PORTARIA_NOMEACAO_CPA_2024.pdf'
              className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> Folder CPA </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/cpa_folder.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> Visão e Missão </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/cpa_visao_missao.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>
        </div>
      </div>

      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1> Relatórios CPA - 2015</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div className='file-card-container'>
          <div className='file-card'>
            <h1 className='file-card-title'> CPA Administração </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/cpa_administracao.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> CPA Direito </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/cpa_direito.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> CPA Enfermagem </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/cpa_enfermagem.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> CPA Serviço Social </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/cpa_servico_social.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>
        </div>

      </div>
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1> Relatórios CPA</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div className='file-card-container'>
          <div className='file-card'>
            <h1
              className='file-card-title center-text'
            > Relatório da Auto-Avaliação Institucional - 2018 </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/cpa_2018.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>
          <div className='file-card'>
            <h1
              className='file-card-title center-text'
            > Relatório da Auto-Avaliação Institucional - 2019 </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/cpa_2019.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1
              className='file-card-title center-text'
            > Relatório da Auto-Avaliação Institucional - 2021 </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/cpa_2021.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1
              className='file-card-title center-text'
            > Relatório da Auto-Avaliação Institucional - 2022 </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/cpa_2022.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1
              className='file-card-title center-text'
            > Relatório da Auto-Avaliação Institucional - 2023 </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/CPA_2023.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>
        </div>


      </div>
      <Footer />
    </>
  );
}
