import React from 'react';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';

export default function PoliticaSocial() {
  const marginSection = {marginTop: '1em'};

  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>PÓS GRADUAÇÃO POLÍTICA SOCIAL E GESTÃO PÚBLICA</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <p>
          Este curso tem o propósito de oferecer elementos teóricos e
          ferramentas necessárias à especialização profissional na área da
          Gestão Pública, especializando gestores e profissionais para
          intervirem na realidade social, política e econômica e assim
          contribuir para a melhoria da gestão das atividades desempenhadas no
          setor público. Oferecendo ao pós graduando uma visão ampla e integrada
          das Políticas Sociais e da Gestão Pública, com reflexões sobre as
          novas tendências e paradigmas administrativos.
        </p>
        <h5 style={marginSection}>Carga Horária Total: 360h</h5>
        <h5 style={marginSection}>
        PÚBLICO ALVO: Graduados com Certificado reconhecido pelo MEC.
        </h5>
        <h4 style={marginSection}>Grade Curricular</h4>
        <ol>
          <li>Metodologia do Ensino Superior</li>
          <li>Metodologia do Trabalho Científico</li>
          <li>Competências profissionais no Mundo Moderno</li>
          <li>Gestão de Pessoas na Administração Pública</li>
          <li>Gestão de Políticas Sociais</li>
          <li>Marketing de Serviços Públicos</li>
          <li>Administração, Planejamento e Orçamento Público</li>
          <li>
          Finanças Públicas
          </li>
          <li>
          Elaboração de Projetos/Captação de Recursos
          </li>
          <li>Licitações e Contratos</li>
          <li>Trabalho de Conclusão de Curso – TCC</li>
        </ol>
      </div>
      <Footer />
    </>
  );
}
