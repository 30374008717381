import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

export default function Radiologia() {
  return (
    <>
      <Header/>
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Radiologia</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <div className='page-image-container'>
          <img src='/assets/cursos/radiologia.jpg'/>
        </div>
        <p>Autorizado pela portaria nº 244 de 06 de Abril de 2018</p>
        <p>
        O curso Superior de Tecnologia em Radiologia da Faculdade de Ciências
        Sociais Aplicadas (FACISA) tem como principal objetivo formar
        profissionais com habilidades e competências para atuação nas mais
        diversas áreas do radiodiagnóstico, imaginologia, terapia e indústria
        para que possam suprir as exigências do mercado de trabalho que se
        encontra cada vez mais competitivo. Busca-se construir uma formação
        profissional generalista, humanista, crítica e, sobretudo ética para que
        os alunos possam ir além da capacitação técnica e instrumental exigidas,
        estando conscientes de suas responsabilidades sociais buscando o
        conhecimento contínuo, ou seja: um profissional pró-ativo e preparado
        para o futuro. Entende-se que, para isso, o aluno deve ser estimulado a
        todo o momento a buscar o auto desenvolvimento com base na sua
        realização pessoal e profissional; mais do que ofertar teorias e
        metodologias prontas, o curso buscará viabilizar a socialização dos
        conhecimentos visando à capacitação cada vez maior do grupo e
        consequentemente do indivíduo. A partir do terceiro período o aluno
        começará a vivenciar a experiência profissional através do estágio
        supervisionado que possibilitará a articulação entre o conhecimento e a
        ação capacitando desde logo o aluno para o exercício profissional, além
        disso, com o objetivo de flexibilizar ainda mais o currículo o aluno
        poderá desenvolver atividades extracurriculares que serão registradas e
        reconhecidas em seu histórico escolar. A matriz curricular implantada
        pela FACISA contempla aspectos de flexibilização, interdisciplinaridade,
        compatibilidade de carga horária total e articulação da teoria com a
        prática estando em consonância com a legislação pertinente e o Catálogo
        Nacional de Cursos Superiores de Tecnologia do Ministério da Educação
        (MEC); Perfil Profissional do Egresso O Tecnólogo em Radiologia formado
        pela FACISA deverá estar apto a desenvolver atividades nas diversas
        áreas do diagnóstico por imagem que vão desde a radiologia convencional,
        mamografia, radioterapia, exames contrastados, tomografia
        computadorizada, ressonância magnética, medicina nuclear, densitometria
        óssea, radiologia odontológica, radiologia veterinária, proteção
        radiológica, radiologia industrial até a gestão de serviços em
        radiologia. Todo o curso está alicerçado numa visão tecnológica, crítica
        e analítica das atividades típicas do profissional ofertando
        conhecimento integrado para desenvolvimento de um olhar mais amplo das
        atividades relacionadas aos serviços. O egresso terá como campo de
        atuação profissional os hospitais, clínicas, unidades especializadas de
        saúde com serviços de diagnóstico por imagem e/ou radiologia industrial,
        comércio de produtos médico-hospitalares e radiológicos, assessoria e
        consultoria técnica como também docência em Radiologia do Ensino
        Superior.
        </p>
      </div>
      <Footer/>
    </>
  );
}
