import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './Slide.css';

export default function Slide() {
  return (
    <>
      <Carousel controls={false} indicators={true} interval={3000}>
        <Carousel.Item>
          <a href='/vestibular' className='slide-link'>
            <img
              className="d-block w-100"
              src="https://imgur.com/VKNMwfW.png"
              alt="Facisa credenciada"
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href='/vestibular' className='slide-link'>
            <img
              className="d-block w-100"
              src="./assets/banner_edital.png"
              alt="Banner de abertura de edital de vertibular"
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href='https://portal.dli.minhabiblioteca.com.br/Login.aspx?key=FACISA' className='slide-link'>
            <img
              className="d-block w-100"
              src="./assets/images/Banner_minhabiblioteca2.png"
              alt="Banner minha biblioteca"
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href='/vestibular' className='slide-link'>
            <img
              className="d-block w-100"
              src="./assets/banner_vestibular_2024.2.png"
              alt="Banner vestibular 2024.2"
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href='https://portal.dli.minhabiblioteca.com.br/Login.aspx?key=FACISA' className='slide-link'>
            <img
              className="d-block w-100"
              src="./assets/images/banner_minhabiblioteca.png"
              alt="Banner minha biblioteca"
            />
          </a>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
