import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Revista.css';

export default function Revista() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>RICSS</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <p>
          A RICSS - Revista Interdisciplinar de Ciência Sociais e Saúde é um
          perídico científico da Faculdade de Ciência Sociais Aplicadas é uma
          realização da gerência Núcleo de Pesquisa e Extensão (NUPEX) da
          Faculdade de Ciências Sociais Aplicada da Bahia- FACISA/BA, cuja
          principal função é a produção, publicização e difusão do conhecimento
          acadêmico-científico contribuindo, dessa forma, com a promoção e o
          desenvolvimento deste na região de abrangência da IES, bem como em
          nível nacional.
        </p>
        <p>
          Entre os principais objetivos desta publicação eletrônica estão:
          divulgar as pesquisas realizadas e em andamento por pesquisadores,
          docentes e alunos dos cursos de gradução, pós-graduação lato sensu,
          produções fruto de projetos de pesquisa, extensão e estágios da
          FACISA, bem como produções de demais profissionais de outras
          faculdades e universidades nos campos temáticos das Ciências Sociais
          Aplicadas, Ciências Humanas e da Saúde, em abordagens disciplinares ou
          transversais (interdisciplinar\multidisciplinar\transdisciplinar),
          visando promover uma melhoria na educação científica da sociedade
          regional e brasileira; criar um espaço de interação entre
          pesquisadores e leitores da revista, com o objetivo de suscitar o
          debate acerca de questões relacionadas a difusão de produções
          científicas relacionadas às áreas supracitadas.
        </p>

        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Edições</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div className="edicao-container">
          <a href="/revista-edicao-1">
            <img src="/assets/imagem_revista.jpg" />
            <h1> 1º Edição </h1>
          </a>

          <a href="/revista-edicao-2">
            <img src="/assets/imagem_revista.jpg" />
            <h1> 2º Edição </h1>
          </a>

          <a href="/revista-edicao-3">
            <img src="/assets/imagem_revista.jpg" />
            <h1> 3º Edição </h1>
          </a>

          <a href="/revista-edicao-4">
            <img src="/assets/imagem_revista.jpg" />
            <h1> 4º Edição </h1>
          </a>

          <a href="/revista-edicao-5">
            <img src="/assets/imagem_revista.jpg" />
            <h1> 5º Edição </h1>
          </a>

          <a href="/revista-edicao-7">
            <img src="/assets/imagem_revista.jpg" />
            <h1> 7º Edição </h1>
          </a>

          <a href="/revista-edicao-8">
            <img src="/assets/imagem_revista.jpg" />
            <h1> 8º Edição </h1>
          </a>

          <a href="/revista-edicao-9">
            <img src="/assets/imagem_revista.jpg" />
            <h1> 9º Edição </h1>
          </a>
        </div>

        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>INSTRUÇÕES GERAIS PARA PUBLICAÇÃO</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div>
          <p>
            Serão publicados trabalhos inéditos, resultantes de pesquisa
            científica da área interdisciplinar em Ciências Sociais Aplicadas,
            Ciências Humanas e Ciências da saúde, apresentados conforme normas
            da Associação Brasileira de Normas Técnicas (ABNT) em vigor: NBR
            6022; NBR 6023 – Referências; NBR 10520 – Citações em documentos.
          </p>
          <p>
            Cada trabalho encaminhado (artigo científico, artigo de revisão,
            ensaios, relatos de pesquisa, relatos de experiência, resenhas,
            comunicações de trabalhos/pesquisa em andamento, será objeto de
            apreciação pelo Comitê Científico (avaliação entre pares), que
            decidirá pela aprovação ou não do trabalho.
          </p>
          <p>
            Os autores serão informados, por e-mail, da aceitação ou não do
            trabalho para publicação. Será garantido o anonimato de autores e
            pareceristas no processo de análise dos trabalhos apresentados.
          </p>
          <p>
            Todos os artigos, dossiês temáticos e resenhas, submetidos a RICSS -
            Revista Interdisciplinar de Ciência Sociais e Saúde serão submetidos
            à apreciação do Conselho Editorial, que analisa sua adequação às
            Normas e à Política Editorial da Revista e decide por seu envio aos
            pareceristas ou sua recusa prévia.
          </p>
          <p>
            Cabe ao Conselho Editorial da Revista decidir pela oportunidade e
            publicação dos trabalhos aprovados pelo Comitê Científico.
          </p>
          <p>
            Os textos devem ser submetidos a uma revisão cuidadosa de linguagem
            antes de serem encaminhados para a revista.
          </p>
          <p>
            Só serão encaminhados para o Comitê Científico os trabalhos que
            atenderem às normas de formatação da Associação Brasileira de Normas
            Técnicas (ABNT). O trabalho que estiver fora das normas será enviado
            ao autor, para realizar os ajustes que se fizerem necessários e
            reencaminhar à revista.
          </p>
          <p>
            À RICSS - Revista Interdisciplinar de Ciência Sociais e Saúde fica
            reservado os direitos autorais no tocante a todos os artigos nela
            publicados.
          </p>
          <p>
            A política de ética de publicação da Revista: i) obedece à Resolução
            nº 466/12, do Conselho Nacional de Saúde, que estabelece as normas
            regulamentadoras sobre pesquisas, envolvendo seres humanos; ii)
            procede ao envio para o (s) autor (es) do parecer conclusivo do
            artigo. Assim sendo, no caso de trabalhos envolvendo pesquisas com
            seres humanos, o número da autorização do Comitê de ética em
            pesquisa deve constar na seção de material e métodos.
          </p>
          <p>
            Se necessário, o artigo aprovado será submetido a pequenas
            correções, visando à melhoria do texto. Cada artigo poderá ter no
            máximo 5 (cinco) autores.
          </p>
        </div>

        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>DIRETRIZES PARA AUTORES</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div>
          <h4>- I PERÍODO DE SUBMISSÃO</h4>
          <p>O período de submissão de manuscritos é contínuo.</p>
          <h5>
            1.0 NORMAS GERAIS PARA PUBLICAÇÃO NA RICSS - Revista
            Interdisciplinar de Ciência Sociais e Saúde
          </h5>
          <p>
            A RICSS - Revista Interdisciplinar de Ciência Sociais e Saúde é um
            periódico anual de Gerência do Núcleo de Pesquisa e Extensão (NUPEX)
            da FACISA/BA. Publica artigos inéditos resultantes de pesquisas
            científicas, relatos de projetos de extensão e estágios, produções
            fruto de Trabalhos de Conclusão de Curso (TCC) de graduação e
            pós-graduação latos senso, além de resenhas de livros. Seu objetivo
            central é divulgar pesquisas e estudos vinculados ao campo
            interdisciplinar, desenvolvidos por pesquisadores de diferentes
            contextos do Brasil.
          </p>
          <h5>2.0 FORMATAÇÃO DO MANUSCRITO</h5>
          <p>
            Os trabalhos só poderão ser submetidos em Português. Deverão ser
            enviados em versão eletrônica (arquivo .doc), digitados com margens
            superior e esquerda de 3 cm e direita e inferior de 2 cm. A fonte
            deverá ser Times New Roman, tamanho 12, espaçamento 1,5; parágrafo
            justificado com recuo de 1,5 cm e entrelinha 1,5. O arquivo não deve
            ter marcas de formatação (estilo, tabulações) nem numeração de
            páginas. Acesse o site da FACISA e faça o download na aba RICSS, dos
            artigos publicados para usar como base na redação do manuscrito.
          </p>
          <p>
            Quanto à extensão dos trabalhos encaminhados, é necessário atender
            aos seguintes requisitos:
            <br /> a) artigos científicos, entre 15 e 20 páginas, incluindo
            figuras, tabelas e referências;
            <br /> b) resenhas, de 3 a 7 páginas.
            <br /> Para a submissão o artigo deverá conter uma página de rosto,
            esse procedimento garantirá o anonimato dos autores quando do envio
            do material para análise dos pareceristas.
          </p>
          <p>
            2.1 PÁGINA DE ROSTO: Na página de rosto, deverão constar o título do
            manuscrito, seguido do Nome dos autores, por extenso, com apenas o
            sobrenome em maiúsculas, duas linhas abaixo do título, alinhado à
            direita; Máximo de 5 autores, a filiação, em nota de rodapé, puxada
            do sobrenome do autor, na qual constem titulação, instituição de
            origem, cidade e estado e o endereço eletrônico do autor. Deve-se
            indicar o autor para correspondência e seus endereços, institucional
            completo e eletrônico (essas informações serão retiradas pela
            Comissão Editorial durante o processo de revisão, para garantir o
            anonimato dos autores) e, abaixo, devem vir: resumo, palavras-chave
            (máximo de cinco, colocadas em ordem alfabética, separadas por ponto
            e grafadas com a inicial maiúscula), abstract, key words (máximo de
            cinco, colocadas em ordem alfabética, separadas por ponto e vírgula
            e grafadas com a inicial maiúscula).
          </p>
          <p>
            2.2 Título do artigo: em maiúsculas e em negrito, centralizado no
            alto da primeira página, em espaçamento simples entrelinhas, tamanho
            12.
          </p>
          <p>
            2.3 Nome do autor: por extenso, com apenas o sobrenome em
            maiúsculas, duas linhas abaixo do título, alinhado à direita; Máximo
            de 5 autores.
          </p>
          <p>
            2.3.1 Filiação: em nota de rodapé, puxada do sobrenome do autor, na
            qual constem titulação, instituição de origem, cidade e estado e o
            endereço eletrônico do autor;
          </p>
          <p>2.4 O resumo e o abstract não poderão exceder 200 palavras.</p>
          <h5>3.0 LIMITES DE PÁGINAS</h5>
          <p>
            O limite de páginas de Artigos, incluindo figuras, tabelas e
            referências, é de 20; enquanto que para as Comunicações Breves e
            Resenhas de livros esse limite é de sete páginas.
          </p>
          <p>
            3.1 No caso de trabalhos envolvendo pesquisas com seres humanos, o
            número da autorização do Comitê de ética em pesquisa deve constar na
            seção de material e métodos.
          </p>
          <h5>4.0 ESTRUTURA</h5>
          <p>
            Os Artigos deverão conter Introdução, Material e Métodos,
            Resultados, Discussão, Agradecimentos (opcional) e Referências. As
            demais formas de publicação não necessitam apresentar as subdivisões
            acima, mas devem seguir essa ordem na apresentação do texto.
          </p>
          <p>
            4.1 Título do artigo: em maiúsculas e em negrito, centralizado no
            alto da primeira página, em espaçamento simples entrelinhas, tamanho
            12.
          </p>
          <p>
            4.2 Resumo, seguido das palavras-chave (máximo de cinco, colocadas
            em ordem alfabética, separadas por ponto e grafadas com a inicial
            maiúscula).
          </p>
          <p>
            4.2.1 Abstract, key words (máximo de cinco, colocadas em ordem
            alfabética, separadas por ponto e vírgula e grafadas com a inicial
            maiúscula).
          </p>
          <p>
            4.3 As tabelas e figuras devem ser inseridas no final do texto,
            numeradas com algarismos arábicos de acordo com sua sequência no
            texto. As tabelas e figuras deverão ter um título segundo a ABNT
            vigente. As Informações adicionais às tabelas e figuras, deverão ser
            dadas em forma de nota de rodapé, embaixo delas. As ilustrações
            podem ser coloridas ou preto e branco.
          </p>
          <h5>5.0 CITAÇOES E REFERÊNCIAS</h5>
          <p>
            As citações de referências no texto devem obedecer a ABNT NBR 10520
            e as referências ABNT NBR 6023. Apenas citações que aparecem no
            texto devem constar na lista de referências. Dados não publicados
            devem ser citados apenas no texto como “dados não publicados” ou
            “comunicação pessoal”, entre parênteses.
          </p>
          <h5>6.0 FIGURAS (FOTOGRAFIAS, GRÁFICOS, DESENHOS, ETC.) E TABELAS</h5>
          <p>
            As tabelas e figuras devem ser inseridas no final do texto,
            numeradas com algarismos arábicos de acordo com sua sequência no
            texto. As tabelas e figuras deverão ter um título segundo a ABNT
            vigente. As Informações adicionais às tabelas e figuras, deverão ser
            dadas em forma de nota de rodapé, embaixo delas. As ilustrações
            podem ser coloridas ou preto e branco.
          </p>
          <h4>- II AVALIAÇÃO E PUBLICAÇÃO</h4>
          <p>
            1. Todos os manuscritos serão avaliados pelos editores em relação ao
            escopo e à formatação da revista. Quando o parecer do corpo
            editorial for favorável, o manuscrito será analisado por dois
            avaliadores, especialistas no tema.
          </p>
          <p>
            2. Os autores receberão os pareceres dos avaliadores e deverão
            encaminhar a nova versão com as adequações ou correções, em um prazo
            máximo de 20 dias em formato eletrônico (.doc).
          </p>
          <p>
            3. A apreciação do artigo pelos pareceristas reside na consistência
            do resumo (apresentando, necessariamente, objetivo, material e
            métodos, resultados e conclusão), outras formas de apresentação
            também serão aceitas; consistência interna do trabalho (com relação
            ao objetivo, introdução, materiais e métodos, resultados e
            discussão); consistência do título (com relação ao conhecimento
            produzido); qualidade do conhecimento educacional produzido (com
            relação à densidade analítica, evidências ou provas das afirmações
            apresentadas e ideias conclusivas); relevância científica (com
            relação aos padrões de uma pesquisa científica); originalidade do
            trabalho (com relação aos avanços da área interdisciplinar) e
            adequação da escrita à norma culta da língua portuguesa.
          </p>
          <h4>- III DIREITO AUTORAL </h4>
          <p>
            Os direitos autorais para os artigos publicados nesta revista são do
            autor, com direitos de primeira publicação para a revista.
          </p>
          <h5>1.0 POLÍTICAS DE DIREITO AUTORAL</h5>
          <p>
            A aprovação dos textos implica a cessão imediata e sem ônus dos
            direitos de publicação em que terá a exclusividade de publicá-los em
            primeira mão. O autor continuará, não obstante, a deter os direitos
            autorais para publicações posteriores. No caso de republicação dos
            artigos em outros veículos, recomenda-se a menção à primeira
            publicação na RICSS - Revista Interdisciplinar de Ciência Sociais e
            Saúde.
          </p>
          <p>
            Nesse sentido, autores que publicam nesta revista concordam com os
            seguintes termos:
            <br />
            a) Autores mantém os direitos autorais e concedem à revista o
            direito de primeira publicação, com o trabalho simultaneamente
            licenciado sob a Licença Creative Commons Attribution que permite o
            compartilhamento do trabalho com reconhecimento da autoria e
            publicação inicial nesta revista.
            <br /> b) Autores têm autorização para assumir contratos adicionais
            separadamente, para distribuição não-exclusiva da versão do trabalho
            publicada nesta revista (ex.: publicar em repositório institucional
            ou como capítulo de livro), com reconhecimento de autoria e
            publicação inicial nesta revista.
            <br /> c) Autores têm permissão e são estimulados a publicar e
            distribuir seu trabalho online (ex.: em repositórios institucionais
            ou na sua página pessoal) a qualquer ponto antes ou durante o
            processo editorial, visto que isso pode gerar alterações produtivas,
            bem como aumentar o impacto e a citação do trabalho publicado.
          </p>
        </div>

        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>POLÍTICAS EDITORIAIS</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div>
          <p>
            O objetivo da Revista Interdisciplinar de Ciências Sociais e Saúde –
            RICSS, é estimular e veicular a produção de conhecimento
            desenvolvida nos campos temáticos das Ciências Sociais e da Saúde,
            em abordagens disciplinares ou transversais
            (interdisciplinar\multidisciplinar\transdisciplinar), proporcionando
            a constituição de um saber em que as dualidades do simples e do
            complexo, do particular e do geral, sejam balizadas por temáticas
            que privilegiem a problematização de questões sob enfoque
            humanístico. Reconhecida e registrada mediante cadastro ISSN
            (International Standard Serial Number), a Revista Interdisciplinar
            de Ciências Sociais e Saúde – RICSS, funcionará como veiculo de
            valorização da atividade de pesquisa e extensão possibilitando a
            publicação do conteúdo enviado nos campos temáticos das áreas de
            Ciênicas Humanas, Ciências Sociais Aplicadas e Ciências da Saúde. O
            público alvo da RICSS são pesquisadores, docentes e estudantes de
            graduação e pós-graduação.
          </p>
          <h5>PERIODICIDADE:</h5>
          <p>A RICSS aceita artigos de modo continuo.</p>
          <h5>POLÍTICAS DE SEÇÃO:</h5>
          <p>
            Serão aceitos manuscritos originais conforme as áreas previstas nas
            políticas editorias nas modalidade de: artigos completos,
            comunicações breves (submissões abertas, indexado e avaliados pelos
            pares ) e resenhas (submissões abertas).
          </p>
          <h5>POLÍTICAS DE SEÇÃO:</h5>
          <p>
            A RICSS oferece acesso livre ao seu conteúdo a partir da data de sua
            publicação, no site da FACISA e em exemplares impressos
            disponibilizados aos autores e nas Bibliotecas.
          </p>
        </div>

        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>EQUIPE EDITORIAL</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <div className='center-text'>
          <p><b>EDITOR CHEFE:</b></p>
          <p>Geíse Pinheiro Pinto, FACISA, BR</p>
          <p><b>EDITOR ASSISTENTE:</b></p>
          <p>Emanuel Vieira Pinto, FACISA, BR</p>
          <p><b>DIRETOR EXECUTIVO:</b></p>
          <p>Jackson Cordeiro de Almeida, FACISA, BR</p>
          <p><b>CONSELHO EDITORIAL:</b></p>
          <p>Eros Bittencourt Shigeto, FACISA, BR<br/>
          Fernanda Spagnol Paganoto, FACISA, BR<br/>
          Fernando Teles Pasotto, FACISA, BR<br/>
          Josiene Andrade de Jesus, FACISA, BR<br/>
          Livia Franca Bohama, FACISA, BR<br/>
          Nathália dos Santos Lima, FACISA, BR<br/>
          Uillians Volkart de Oliveira, FACISA, BR</p>
        </div>

      </div>
      <Footer />
    </>
  );
}
