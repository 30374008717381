import React from 'react';
import {BsWhatsapp} from 'react-icons/bs';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Ajuda.css';

export default function Ajuda() {
  return (
    <>
      <Header />

      <div className="page-wrap">
        <div
          className='title-edital'
        >
          <h1>Como realizar sua inscrição</h1>
        </div>
        <div className="vestibular-buttons">
          <a
            href="http://cesesb.com/projetos/concurso/inscricao/index.php?&tid=0&lid=0&&pid=concurso"
            className="btn btn-primary"
            id='btn-edital'
          >
            Quero me inscrever
          </a>
          <a
            href="http://cesesb.com/projetos/concurso/inscricao/index.php?&tid=0&lid=0&&pid=concurso"
            className="btn btn-success"
            id='btn-edital'
          >
            Acompanhe sua inscrição
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=557388665337&text&type=phone_number&app_absent=0"
            className="btn btn-success"
            id='btn-edital'
          >
            Tire suas dúvidas pelo whatsapp <BsWhatsapp />
          </a>
        </div>

        <div className="vestibular-instrucoes">
          <ol className="vestibular-passo">
            <li>Escolha o seu Curso.</li>
            <li>Informe seus dados para o castro.</li>
            <li>Realize a prova.</li>
            <li>Aguarde o resultado.</li>
            <li>Faça sua matrícula.</li>
            <li>
              O Método de Avaliação será através de Redação. Na área de (Exames
              de Seleção Online) coluna (Ações), clique no ícone (Fazer a
              redação do exame de seleção online). Faça a sua redação de acordo
              com o tema apresentado e o tempo, no final da página clique em
              (Finalizar Redação).
            </li>
          </ol>

          <div className="vestibular-video">
            <video controls width="250">
              <source src="/assets/como_se_inscrever.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
