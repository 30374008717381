import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Ceam() {
  return (
    <>
      <Header/>
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>CEAM</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
        O Centro Especializado de Atendimento à Mulher é um projeto desenvolvido
        pelo curso de Serviço Social, que atende mulheres vítimas de violência,
        considerando os vários tipos: violência física, violência sexual,
        violência psicológica, violência patrimonial, violência moral. O CEAM,
        presta um serviço de acolhimento, atendimento social, psicológico e
        ambulatorial, orientação e encaminhamento jurídico à mulher em situação
        de violência. Tem como objetivo proporcionar subsídios necessários à
        superação da situação de violência, contribuindo para o fortalecimento
        da mulher e o resgate de sua cidadania. O CEAM atende à demanda do
        município de Itamaraju e a região do Extremo Sul da Bahia, sendo este
        atendimento de forma gratuita: Segunda a Sexta das 13h às 17h
        </p>
      </div>
      <Footer/>

    </>
  );
}
