import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function NPJ() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Núcleo de Prática Jurídica</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          Núcleo de Práticas Jurídicas da FACISA é órgão encarregado de
          organizar, coordenar e supervisionar a realização do Estágio
          Supervisionado e tem como marco orientador a integração
          teórica-prática, na forma definida na regulamentação do NPJ. Prepara o
          aluno para que ele dominar conteúdos indispensáveis ao exercício das
          diversas carreiras contempladas pela formação jurídica e outras
          atividades práticas, desenvolvidas pelos alunos do Curso, em
          conformidade com a Resolução CNE/CES 09/2004 e a Lei 8.906/1994, que
          dispõe sobre o Estatuto da Advocacia e da Ordem dos Advogados do
          Brasil. O Núcleo de Práticas Jurídicas presta assistência jurídica
          gratuita à população carente do município de Itamaraju - BA, por meio
          de ações, acompanhamento de processos, celebração de acordos, dentre
          tantas outras atividades inerentes ao profissional de Direito na
          prestação da assistência jurídica, consoante a Constituição Federal de
          88, art. 5º LXXIV.
        </p>
        <p> O Núcleo de Práticas Jurídicas funciona na sede da
          FACISA, na Rodovia BR 101, km 808, nº. 1130, Bairro Santo Antônio do
          Monte, nesta cidade. Para receber atendimento, basta ir ao local de
          funcionando de segunda a sexta-feira das 14:00h às 17:00h. Ressalte-se
          que nos períodos de férias ou recessos escolares haverá plantão, em
          horário fixado de acordo com o funcionamento da FACISA, com a
          finalidade de prestar assistência de urgência e acompanhar os
          processos em andamento.</p>
      </div>
      <Footer />
    </>
  );
}
