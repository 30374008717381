import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Secretaria() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Secretaria Acadêmica</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          A Secretaria Acadêmica é a responsável pela vida acadêmica do aluno
          dentro da Instituição. Também é responsável pelas entradas de
          requerimento por parte dos alunos.
        </p>
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Atendimento:</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          Segunda a Sexta, 8:00 às 12:00 e 14:00 às 18:00 Sábado, 8:00 às 12:00.
        </p>
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Regimento da Secretaria Acadêmica</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          Art. 19. Ao Secretáriao Geral d FACISA, indicado pela Mantenedora,
          compete: I - Organizar os serviços da Secretaria-Geral, concentrando
          nela toda a escrituração do estabelecimento, a qual deverá ser mantida
          rigorosamente atualizada e conferida; II - Organizar o arquivo do modo
          que assegure a preservação dos documentos escolares e que se atenda
          prontamente a qualquer pedido de informação ou esclarecimento de
          interessados ou da Diretoria-Geral; III - Cumprir e fazer cumprir os
          despachos e determinações da Diretoria-Geral; IV - Redigir e fazer
          expedir toda a correspondência da FACISA; V - Superintender e
          fiscalizar os serviços da Secretaria-Geral podendo delegar poderes a
          seus auxiliares; VI - Redigir e subscrever os editais de chamada para
          exame e matrícula, os quais serão publicados por ordem da
          Diretoria-Geral; VII - Manter atualizada a coleção de leis,
          regulamentos, instruções, ordens de serviço, despachos e livros de
          Escrituração Escolar; VIII - Apresentar à Diretoria da FACISA, em
          tempo hábil, todos os documentos que devam ser assinados e visados; IX
          - Subscrever e fazer publicar regularmente, o quadro de notas de
          aproveitamento das avaliações e relação de faltas para reconhecimento
          do aluno; X - Organizar e manter atualizados os prontuários dos
          alunos; XI - Comunicar a Tesouraria para fins de registro e
          orientação, imediatamente após a escrituração, as disciplinas, bem
          como os números atribuídos aos alunos que sejam matriculados e
          daqueles que tenham sido transferidos; XII - Encaminhar mensalmente à
          Tesouraria a relação dos alunos cujas matrículas tenham sido
          canceladas; XIII - Manter o sigilo requerido pela função e exercer as
          demais atividades que lhe forem confiadas; Art. 20. Aos auxiliares de
          Secretaria compete executar os serviços da Secretaria-Geral que lhe
          forem atribuídos pelo Secretário-Geral.
        </p>
      </div>
      <Footer />
    </>
  );
}
