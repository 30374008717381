import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Requerimento.css';

export default function Requerimento() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Requerimento</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <p>
          A Diretoria de Admissão, é vinculada Ao setores na FACISA, sendo
          responsável pelo registro e controle dos atos acadêmicos, financeiro
          do discente de graduação, desde sua entrada na instituição até o
          momento da Colação de Grau. A função principal é realizar de forma
          adequada o registro, contabilização e arquivamento de dados referentes
          à matrícula institucional, resultado de desempenho, aproveitamento de
          estudo, transferências e outras atividades de alunos regularmente
          matriculados nos cursos em consonância com a Legislação da
          Universidade. Além dos discentes regularmente matriculados registrando
          o acompanhamento da trajetória acadêmica dos alunos especiais e
          egressos da instituição, consolidando a emissão oficial de documentos
          como declarações, certidões, históricos, e diplomas.
        </p>
        <p> Com o objetivo de
          atender satisfatoriamente a comunidade acadêmica e o público externo,
          o setor funciona nos três turnos, nos mesmos horários da instituição ,
          disponibilizando serviços como emissão de diversos documentos
          acadêmicos que comprovem vínculo do estudante de graduação ou do
          egresso com a FACISA. Atendendo as normas e prazos consolidada a cada
          solicitação com valores, setores e responsáveis distintos nosso
          requerimento é totalmente ONLINE da abertura até a conclusão do mesmo
          onde o requerente acompanha o processo com o numero de PROTOCOLO
          gerado na conclusão do requerimento guarde esse protocolo ate a
          conclusão do mesmo.</p>
        <div className='requerimento-btn'>
          <a className="btn btn-primary" type="submit"
            href='http://cesesb.com/projetos/nucleo/uteis/login.php?&tid=0&lid=0&pid=29&arq_ret=R5QT1WSRQBMCVQVPFFQSFP91CC5STQFVQPRF0PBQW1M910Q4R4CV59RWRF1F5SWCW0'>
          Solicitar
          </a>
          <a className="btn btn-primary" type="submit"
            href='http://cesesb.com/projetos/nucleo/uteis/login.php?&tid=0&lid=0&pid=29&arq_ret=R5QT1WSRQBMCVQVPFFQSFP91CC5STQFVQPRF0PBQW1M910Q4R4CV59RWRF1F5SWCW0'>
          Consultar Solicitação
          </a>
        </div>
        <p className='center-text'>
          Caso não possua cadastro de Usuário, favor entrar em contato com a
          Secretaria. E-mail: centralderequerimentosfacisa@gmail.com
        </p>
      </div>
      <Footer />
    </>
  );
}
