import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

export default function Administracao() {
  return (
    <>
      <Header/>
      <div className='page-wrap'>
        <div className='page-title-wrap'>
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Administração</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <div className='page-image-container'>
          <img src='/assets/cursos/adm.webp'/>
        </div>
        <p>Reconhecido pela Portaria n.º 223 de 07 de junho de 2006</p>
        <p>
        O Curso apresenta uma grade curricular construída para atender as
        exigências e inovar no mercado de trabalho. O segmento de gestão de
        negócios se encontra em ascensão. As dificuldades de comercialização de
        bens e serviços passaram a constituir uma matéria específica; já houve
        quem colocasse que (a maior causa do insucesso empresarial é a
        comercialização). (Não adianta produzir bem, se não sabemos como, por
        quanto e onde vendermos). A atuação dos especialistas em gestão de
        negócios é cada vez mais requisitada pela sociedade; dignos de bons
        salários, pois apresentam as soluções para os problemas de
        comercialização. Esses profissionais se defrontam com grandes
        oportunidades, podendo atuar como consultores, projetistas, especialista
        em mercados, dentre outras áreas.
        </p>
      </div>
      <Footer/>

    </>
  );
}
