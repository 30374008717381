import React, {useState, useEffect} from 'react';
import {BsWhatsapp} from 'react-icons/bs';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Edital.css';
import CardCurso from '../../components/CardCurso/CardCurso';
import db from '../../assets/db/cursos_db';


export default function Edital() {
  const [mostrarConteudo, setMostrarConteudo] = useState(false);

  useEffect(() => {
    // Define mostrarConteudo como true após o DOM ser carregado
    setMostrarConteudo(true);
  }, []); // O segundo argumento vazio faz com que o useEffect
  // seja executado apenas uma vez, após a montagem do componente
  return (
    <>
      <Header />

      <div className={`title-edital ${mostrarConteudo ? 'mostrar' : ''}`}
        id="conteudo">
        <h1>Sai o edital do vestibular 2024.2</h1>
      </div>
      <div
        className={`page-edital ${mostrarConteudo ? 'mostrar' : ''}`}
        id="conteudo"
      >
        <div className="vestibular-buttons">
          <a
            href="/assets/edital_vestibular_2024_2.pdf"
            className="btn btn-primary"
            id='btn-edital'
          >
            Edital
          </a>

          <a
            href="http://cesesb.com/projetos/concurso/inscricao/index.php?&tid=0&lid=0&&pid=concurso"
            className="btn btn-primary"
            id='btn-edital'
          >
            Quero me inscrever
          </a>
          <a
            href="http://cesesb.com/projetos/concurso/inscricao/index.php?&tid=0&lid=0&&pid=concurso"
            className="btn btn-success"
            id='btn-edital'
          >
            Acompanhe sua inscrição
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=557388665337&text&type=phone_number&app_absent=0"
            className="btn btn-success"
            id='btn-edital'
          >
            Tire suas dúvidas pelo whatsapp <BsWhatsapp />
          </a>
        </div>

        <div>
          <div>
            <img
              src="./assets/faixada-facisa.png"
              href="/assets/edital_vestibular_2024_1.pdf"
              className="img-noticia d-block"
              alt="imagem de faixada facisa"
            />
          </div>

          <div className="noticia-vestibular">
            <p>
              A FACISA – Faculdade de Ciências Sociais Aplicadas, uma das
              melhores faculdades do Brasil, classificada como a 18ª faculdade
              de Direito do Brasil pelo MEC, com um número significativo de
              aprovações na OAB e agora com uma aprovação de 6 egressos no
              Tribunal de Justiça da Bahia. Abre suas inscrições para o
              vestibular de 2024.2, com cursos despontando no cenário regional e
              estadual com excelência, como é o caso do curso de Odontologia
              formando cirurgiões dentistas de alto nível, o de Gastronomia com
              o melhor laboratório da Bahia, Medicina Veterinária com uma
              clínica escola de excelência, Farmácia com seus laboratórios
              específicos, Radiologia e o curso de Administração consolidado
              tendo como diferencial todo aluno que forma já sai colocado no
              mercado de trabalho.
            </p>
            <p>
              Essa é a oportunidade de fazer uma faculdade de alto nível numa
              grande perspectiva de futuro por um custo bem menor do que ter que
              se deslocar para um grande centro e ainda não ter a certeza da
              qualidade. Hoje a educação está de certa forma muito comprometida
              em decorrência da importância que estão dando à formação.
            </p>
          </div>
        </div>
      </div>
      <div id="cursos-edital">
        <h4>CURSOS OFERECIDOS PELA FACISA:</h4>
        <div className="cursos-container">
          {db.map((curso) => (
            <CardCurso
              key={curso.curso}
              imagem={curso.imagem}
              titulo={curso.curso}
              periodo={curso.periodo}
              semestres={curso.semestres}
              desc={<div className="descricao-limitada">{curso.descricao}</div>}
              linkwhats={curso.linkwhats}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
