import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Ouvidoria.css';

export default function Ouvidoria() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Ouvidoria</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p className="center-text">
          Para mais informações, sugestões ou dúvidas, entre em contato conosco
          através do formulário abaixo (clique na imagem) ou ligue.
        </p>
        <div className="ouvidoria-container">
          <div >
            <ul className="company-address">
              <li>
                <p>Endereço: BR 101, Km 808, nº 1130 </p>
                <p>Itamaraju - BA</p>
              </li>
              <li>
                <p>Telefone: (73)3294-3690</p>
                <p>Whatsapp: (73)98866-5337</p>
              </li>
              <li>
                <i className="fa fa-envelope-o"></i>
                <a>Email: matriculasfacisa@gmail.com</a>
                <a></a>
              </li>
            </ul>
          </div>
          <a href="http://cesesb.com/projetos/ouvidoria/index.php?&tid=0&lid=0&sid=ec19661ecad&&pid=21">
            <img
              src="https://portal.ifba.edu.br/barreiras/imagens-campus-barreiras/icon-telefone.png/@@images/24174166-eebf-419e-97eb-1826d6dcf5ea.png"
              alt=""
            />
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}
