import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Biblioteca() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>APRESENTAÇÃO</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <p>
          A Biblioteca é um espaço onde são salvaguardados e disseminado as
          informações, tem como principal objetivo fornecer um bom conteúdo
          informacional e uma estrutura de qualidade para a comunidade
          acadêmica. A figura do bibliotecário é muito importante para o
          desenvolvimento, organização e construção de um sistema de biblioteca
          multidisciplinar, pois ele deve está atento as mudança para oferecer
          sempre o melhor para seus usuários. A Faculdade de Ciências Sociais
          Aplicadas - FACISA dispõe de uma biblioteca, que foi criada com o
          objetivo de atender e suprir as necessidades informacionais dos seus
          usuários internos e externos, disponibilizando conteúdo, com
          eficiência, através da seleção, armazenamento, recuperação e
          divulgação da bibliografia, dando apoio ao ensino, pesquisa e
          extensão.
        </p>
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>PRODUTOS E SERVIÇOS OFERECIDOS</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          Consulta ao Catalogo; Disseminação seletiva de informação (DSI);
          Acesso à internet; Empréstimo de livros; Acesso às bases de dados
          bibliográficos; Orientações sobre a elaboração de trabalhos acadêmicos
          (monografia); Doações de livros; Processamento técnico; Catalogação de
          livros (AACR2); Classificação de livros (CDD); Tabela Cutter; Controle
          de periódicos; Reserva e empréstimo informatizado; Aquisição de
          livros; Sistema de gerenciamento de Acervo da Biblioteca (Sophia);
          Orientação de trabalhos acadêmicos (ABNT); A Biblioteca da FACISA
          oferece uma estrutura física e de serviço adequada para seus alunos
          com climatização da área física, sala de estudo individual, mesas para
          estudo e sistema anti furto.
        </p>
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>ACERVO</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          O acervo está informatizado (Sophia) e a base de dados bibliográfica é
          composta de livros, monografias, dicionários, periódicos, artigos de
          periódicos, enciclopédias, normas técnicas, CD-Rom, DVD e VHS.
          Atualmente conta com 2.881 mil títulos de livros, 952 monografias
          aproximadamente, 143 vídeos. O acervo bibliográfico da FACISA/CESESB é
          de acesso livre a professores, funcionários, alunos e o público em
          geral. Não é permitida a entrada de pessoas com alimentos ou bebidas
          na biblioteca.
        </p>

        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>FUNCIONAMENTO</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          Segunda a Sexta-feira das 07:00h às 13:00H, de 14:00 às 22:30h Sábado
          das 08:00 às 12:00h. Emanuel Vieira Pinto Graduação UFBA - Instituto
          de Ciência da Informação Bibliotecário CRB-5/1618 Especialista em
          Docência do Ensino Superior Mestre em Gestão Social, Educação e
          Desenvolvimento Regional - UNIVC Pesquisador Institucional da FACISA
          Coordenador do NTCC.
        </p>
      </div>
      <Footer />

    </>
  );
}
