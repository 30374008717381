import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

export default function Gastronomia() {
  return (
    <>
      <Header/>
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Gastronomia</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <div className='page-image-container'>
          <img src='/assets/cursos/gastronomia.jpeg'/>
        </div>
        <p>Reconhecido: pela Portaria n.º 254 12 de abril de 2018.</p>
        <p>
        O curso Superior de Tecnologia em Gastronomia contempla uma duração de
        dois anos e meio, totalizando cinco (05) semestres letivos. O referido
        curso apresenta uma grade curricular completa, abrangendo diversas áreas
        da gastronomia, integrando os alunos no mercado de trabalho, de forma a
        estarem aptos ao exercício desta profissão, com ética e compromisso
        profissional e social. O objetivo do curso é possibilitar uma formação
        voltada ao desenvolvimento de competências e habilidades para que os
        egressos possam: Compreender as questões científicas, éticas, técnicas,
        sociais, econômicas, empresarias e culturais relacionadas ao setor em
        questão; Assimilar contínuos e novos conhecimentos do setor gastronômico
        regional, nacional e internacional; Analisar a viabilidade para
        implantação de um empreendimento e projetos de alimentação; Planejar e
        executar estudos mercadológicos; Utilizar práticas de gestão sustentável
        e de inclusão social; Elaborar cardápios, decoração de pratos e serviço
        de mesa; Gerenciar empreendimentos alimentícios (restaurantes, bares,
        etc.); Desenvolver soluções em gastronomia; Contextualizar como
        expressão histórica e cultural; Dominar as ferramentas artísticas e
        tecnológicas do ramo; Aplicar os critérios de higiene e segurança em
        alimentação; Elaborar e apresentar pratos de culinárias específicas;
        Planejar, supervisionar, elaborar e coordenar serviços e eventos
        gastronômicos; Avaliar o impacto da atividade profissional no contexto
        social e ambiental dentre outros. O presente curso foi estruturado
        segundo o Catálogo Nacional de Cursos Superiores de Tecnologia. Os
        alunos de cursos superiores tecnológicos também concluem o curso
        recebendo diploma de nível superior, e podem prosseguir seus estudos
        posteriormente, fazendo cursos de pós-graduação. É possível obter
        informações complementares com a coordenação do curso, a professora
        Mestre Fernanda Spagnol Paganoto, a qual se encontra na instituição de
        segunda a sexta-feira, das 14 às 17 horas e das 19 às 22:30. Duração: 2
        anos e meio.
        </p>
      </div>
      <Footer/>

    </>
  );
}
