/* eslint-disable max-len */
export default [
  {
    title: 'A UM OLHAR DA ENFERMAGEM NA VIGILÂNCIA SANITÁRIA',
    url: '/assets/revista/3_edicao/01.pdf',
  },
  {
    title: 'ASPECTOS EPIDEMIOLÓGICOS DO OFIDISMO EM ILHÉUS',
    url: '/assets/revista/3_edicao/02.pdf',
  },
  {
    title: 'EDUCAÇÃO E INCLUSÃO UM OLHAR SOBRE AS POLÍTICAS PÚBLICAS BRASILEIRAS DE INCLUSÃO DE PESSOAS COM NECESSIDADES EDUCACIONAIS ESPECIAIS EM SALAS',
    url: '/assets/revista/3_edicao/03.pdf',
  },
  {
    title: 'HIV/AIDS UM CONTEXTO MARCADO POR EXCLUSÃO SOCIAL',
    url: '/assets/revista/3_edicao/04.pdf',
  },
  {
    title: 'MÉTODOS DE AUTOCOMPOSIÇÃO MEDIAÇÃO, ARBITRAGEM, NEGOCIAÇÃO E CONCILIAÇÃO JUDICIAL',
    url: '/assets/revista/3_edicao/05.pdf',
  },
  {
    title: 'O ATIVISMO JUDICIAL NO BRASIL',
    url: '/assets/revista/3_edicao/06.pdf',
  },
  {
    title: 'O USO DA COMUNICAÇÃO EMPRESARIAL NO PLANEJAMENTO ESTRATÉGICO DA EMPRESA',
    url: '/assets/revista/3_edicao/07.pdf',
  },
  {
    title: 'SERVIÇO SOCIAL BRASILEIRO DO BERÇO DA BURGUESIA PARA A A COSNTRUÇÃO DE UM PROEJTO ÉTICO POLÍTICO',
    url: '/assets/revista/3_edicao/08.pdf',
  },
  {
    title: 'SERVIÇO SOCIAL NO CAMPO SÓCIOJURÍDICO UMA ANÁLISE DA INTERVENÇÃO PROFISSIONAL NA GARANTIA DOS DIREITOS SOCIAIS',
    url: '/assets/revista/3_edicao/09.pdf',
  },
];


