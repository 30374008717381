import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Institucional() {
  return (
    <>
      <Header/>
      <div className='page-wrap'>
        <div className='page-title-wrap'>
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1> Institucional </h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <p>
        O Centro de Ensino Superior do Extremo Sul da Bahia S/C LTDA - CESESB,
        Mantenedora da Faculdade de Ciências Sociais Aplicadas- FACISA, tem sede
        e foro na cidade de Itamaraju, Estado da Bahia, na BR 101, km 808, nº
        1.130, Bairro: Santo Antônio do Monte, CEP 45.836-000. Faz parte da
        Mesorregião Econômica do Extremo Sul da Bahia, da Microrregião de Porto
        Seguro e a 8ª Região Administrativa de Saúde e de Educação.
        </p>
        <p>
        O Centro de Ensino Superior do Extremo Sul da Bahia S/C LTDA - CESESB,
        entidade jurídica de direito privado, inscrita no CNPJ/MF sob nº
        02.611.487/0001 - 74, constituída pelo contrato particular de sociedade
        civil por cotas de responsabilidade limitada, conforme o Protocolo nº
        6.192, lavrado em 30/06/98, Livro A-1, fls. 273, e registrado no Livro
        A-1, nº 160, fls. 163/164 no Cartório de Registro Civil de Pessoas
        Jurídicas da Comarca do município de Itamaraju/BA. As alterações
        contratuais realizadas em 11 de maio de 1999 e em 25 de julho de 2001,
        foram protocoladas sob nº.6.649, fls. 298 e 7.328, fls. 33, ambas do
        Livro A-2 e averbações no Livro A-1, sob nº 160, fls.163 v,
        respectivamente, em 24 de novembro de 1999 e em 25 de outubro de 2001,
        no mesmo cartório supra citado.
        </p>
        <p>
        Nascido para viabilizar projetos na área social, em nível superior, o
        CESESB, partiu de um amplo estudo que apontou as lacunas,
        primordialmente relacionadas à necessidade de ações sociais junto à
        população, no município de Itamaraju. A Mantenedora tem por objetivo
        concorrer para o desenvolvimento da educação na região de abrangência de
        sua inserção geográfica, nas diversas áreas e níveis, desde que de
        acordo com a legislação vigente; portanto, manter instituições de
        ensino, oferecendo ao estudante cursos atualizados, dinâmicos, com total
        inserção na modernidade. Pode também prestar consultoria e planejamento
        na área de educação, além de contribuir para o aprimoramento cultural.
        Porém, saliente-se que em sua constituição está declarado o compromisso
        com o desenvolvimento da solidariedade humana e aperfeiçoamento do ser
        humano.
        </p>
      </div>
      <Footer/>
    </>
  );
}
