import React from 'react';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';

export default function DireitoTrabalho() {
  function templateString(string) {
    return string
        .toLowerCase()
        .split(' ')
        .map((word) => {
          if (word.length < 3) {
            return word;
          } else {
            return word[0].toUpperCase() + word.slice(1);
          }
        })
        .join(' ');
  }

  const marginSection = {marginTop: '1em'};

  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>PÓS GRADUAÇÃO EM DIREITO DO TRABALHO</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <div>
          <p>
          Para que o profissional do direito exerça o seu mister com excelência,
          deve ter pleno conhecimento e domínio das profundas transformações que
          estão ocorrendo em nosso sistema jurídico. A Especialização em Direito
          e Processo do Trabalho busca habilitar o aluno a tratar com
          conhecimentos sólidos, os mais relevantes e atuais temas que cercam o
          direito do trabalho, oferecendo condições de gerir de forma crítica e
          reflexiva, o contrato de trabalho desde a sua formação até a extinção;
          aliando ao estudo específico do curso, temas correlatos.
          </p>
        </div>
        <h5 style={marginSection}>Carga Horária Total: 360h</h5>
        <h5 style={marginSection}>
          {templateString('PÚBLICO ALVO: BACHARÉIS EM DIREITO')}
        </h5>
        <h4 style={marginSection}>Grade Curricular</h4>
        <ol>
          <li>Metodologia do Ensino Superior</li>
          <li>Metodologia do Trabalho Científico</li>
          <li>Competências profissionais no Mundo Moderno</li>
          <li>Introdução ao Direito do Trabalho</li>
          <li>Direito Individual e Coletivo do Trabalho</li>
          <li>Teoria Geral do Direito Processual do Trabalho</li>
          <li>Princípios e Fontes do Direito Processual do Trabalho</li>
          <li>
            Dissídio Individual e Coletivo do Trabalho (Recursos e execução
            trabalhista)
          </li>
          <li>
            Núcleo Estratégico Trabalhista (Cálculo Trabalhista e Negociação em
            Relação do Trabalho)
          </li>
          <li>Estado Constituição e Trabalho</li>
          <li>Trabalho de Conclusão de Curso – TCC</li>
        </ol>
      </div>
      <Footer />
    </>
  );
}
