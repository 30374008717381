import React from 'react';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';

export default function EnfermagemEstomaterapia() {
  const marginSection = {marginTop: '1em'};

  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1 className='center-text'>
            PÓS GRADUAÇÃO EM ESPECIALIZAÇÃO EM ENFERMAGEM EM ESTOMATERAPIA:
            ESTOMIAS, FERIDAS E INCONTINÊNCIAS
          </h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <p>
          Proporcionar ao enfermeiro competências e habilidades para o cuidado a
          pessoas com estomas, incontinência fecal e urinária, fístulas, drenos,
          cateteres e feridas agudas e crônicas, abrangendo os aspectos de
          prevenção, tratamento, reabilitação e elaboração de programas ou
          organização de serviços e protocolos de atenção além de contribuição
          para o desenvolvimento de políticas públicas na área.
        </p>
        <h5 style={marginSection}>Carga Horária Total: 510h</h5>
        <h5 style={marginSection}>
          PÚBLICO ALVO: Ter o título de graduação em Enfermagem há dois anos e
          possuir dois anos de experiência clínica-cirúrgica geral ou
          específica, em uma das áreas da estomaterapia: feridas, estomias e/ou
          incontinências.
        </h5>
        <h4 style={marginSection}>Grade Curricular</h4>
        <ol>
          <li>Metodologia do Ensino Superior</li>
          <li>Metodologia do Trabalho Científico</li>
          <li>Bioestatística</li>
          <li>Práticas educativas</li>
          <li>Sistematização da assistência em enfermagem em estomaterapia</li>
          <li>Fundamentos para a prática clínica em estomaterapia: nutrição</li>
          <li>
            Fundamentos para a prática clínica em estomaterapia: psicologia
          </li>
          <li>
            Fundamentos para a prática clínica em estomaterapia: anatomia e
            fisiologia
          </li>
          <li> Cuidar em estomias I</li>
          <li>Cuidar em estomias II</li>
          <li>Cuidar em estomias III</li>
          <li>Cuidar em feridas I</li>
          <li>Cuidar em feridas II</li>
          <li>Cuidar em feridas III</li>
          <li>Cuidar em incontinência I</li>
          <li>Cuidar em incontinência II</li>
          <li>Cuidar em incontinência III</li>
          <li>Docência do Ensino Superior</li>
        </ol>
      </div>
      <Footer />
    </>
  );
}
