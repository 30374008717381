/* eslint-disable max-len */
export default [
  {
    title: 'ABORDAGEM INICIAL E O PERFIL EPIDEMIOLÓGICO DO TÉTANO EM ITACARÉ BAHIA',
    url: '/assets/revista/2_edicao/01.pdf',
  },
  {
    title: 'A EDUCAÇÃO COMO DIREITO HUMANO: POLÍTICAS PÚBLICAS EFETIVADORAS',
    url: '/assets/revista/2_edicao/02.pdf',
  },
  {
    title: 'A IMPORTÂNCIA DO DIAGNOSTICO PRECOCE DO INFARTO AGUDO DO MIOCARDIO NO ATENDIMENTO PRE-HOSPITALAR',
    url: '/assets/revista/2_edicao/03.pdf',
  },
  {
    title: 'ASSISTÊNCIA DE ENFERMAGEM AO PACIENTE COM TRAUMATISMO CRÂNIO-ENCEFÁLICO NO ATENDIMENTO PRÉ-HOSPITALAR',
    url: '/assets/revista/2_edicao/04.pdf',
  },
  {
    title: 'A EXPLORAÇÃO DO TRABALHO INFANTO - JUVENIL A LUZ DA CONSOLIDAÇÃO DAS LEIS DO TRABALHO E DO ESTATUTO DA CRIANÇA E DO ADOLESCENTE',
    url: '/assets/revista/2_edicao/05.pdf',
  },
  {
    title: 'A UTILIZAÇÃO DE REDES SOCIAIS PELO EMPREGADO E AS REPERCUSSÕES NO CONTRATO DE TRABALHO',
    url: '/assets/revista/2_edicao/06.pdf',
  },
  {
    title: 'DISTRIBUIÇÃO DE COMPLICAÇÕES CARDIOVASCULARES EM IDOSOS HIPERTENSOS EM ILHÉUS – BAHIA NO PERÍODO DE 2002 A 2011',
    url: '/assets/revista/2_edicao/07.pdf',
  },
  {
    title: 'ELEMENTO OBJETIVO E SUBJETIVO DO ATO DE IMPROBIDADE ADMINISTRATIVA',
    url: '/assets/revista/2_edicao/08.pdf',
  },
  {
    title: 'GESTÃO DO TRABALHO NO SISTEMA ÚNICO DE ASSISTÊNCIA SOCIAL NO MUNICÍPIO DE TEIXEIRA DE FREITAS',
    url: '/assets/revista/2_edicao/09.pdf',
  },
  {
    title: 'IMPORTÂNCIA DO MANEJO CORRETO NO ATENDIMENTO DA CRIANÇA VÍTIMA DE TCE',
    url: '/assets/revista/2_edicao/10.pdf',
  }, {
    title: 'O PAPEL DA AUDITORIA INTERNA COMO REDUTOR DE RISCOS PARA AUDITORIA EXTERNA',
    url: '/assets/revista/2_edicao/11.pdf',
  },
  {
    title: 'O PROCESSO DE IMPLANTAÇÃO DA UNIVERSIDADE FEDERAL DO SUL BAHIA – UFSBA',
    url: '/assets/revista/2_edicao/12.pdf',
  },
  {
    title: 'O VALOR JURÍDICO DO SILÊNCIO',
    url: '/assets/revista/2_edicao/13.pdf',
  }, {
    title: 'PRECAUÇÕES PADRÃO NO CONTEXTO DA PREVENÇÃO DE ACIDENTES DE TRABALHO NA ENFERMAGEM',
    url: '/assets/revista/2_edicao/14.pdf',
  },
  {
    title: 'SISTEMAS DE GESTÃO COMO FERRAMENTA DE AUXÍLIO NA GESTÃO TRIBUTÁRIA',
    url: '/assets/revista/2_edicao/15.pdf',
  },

];

