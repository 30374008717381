import React from 'react';
import './Footer.css';

export default function Footer() {
  return (
    <footer className='footer-container'>
      <div className='footer-image-container'>
        <img src='/assets/images/logo.png' />
      </div>
      <div className='footer-image-container'>
        <img src='/assets/images/banner_qrcode.png' width={350} />
      </div>

      <div className='footer-info-container'>
        <div className='footer-info-wrap'>
          <p>Sede: BR 101, KM 808, 1130</p>
          <p> CEP 4583600 - Itamaraju/BA</p>
          <p>Telefone: (73) 3294-3690</p>
        </div>
        <div className='footer-vertical-pipe'></div>
        <div className='footer-info-wrap footer-copyright'>
          <p>© Copyright 2022. </p>
          <p>Todos os Direitos Reservados para FACISA/BA.</p>
        </div>
      </div>

      <div className='footer-copyright-responsive'>
        <p>© Copyright 2022. </p>
        <p>Todos os Direitos Reservados para FACISA/BA.</p>
      </div>
    </footer>
  );
}
