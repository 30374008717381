import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

export default function ServicoSocial() {
  return (
    <>
      <Header/>
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Serviço Social</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <div className='page-image-container'>
          <img src='/assets/cursos/servico.jpg'/>
        </div>
        <p>Autorização: pela portaria nº1619 de 13 de novembro de 2009</p>
        <p>
        O curso de Serviço Social da FACISA, é reconhecido pelo MEC com nota 4,
        igualando-se às grandes universidades do país, busca formar
        profissionais Assistentes Sociais com capacidade de análise da realidade
        social, de fazer intervenções junto às demandas presentes na sociedade,
        e de formular respostas profissionais para o enfrentamento das
        desigualdades sociais considerando as possibilidades de avanço no campo
        dos direitos sociais expressos nos diversos espaços sócias. Com duração
        de 4 anos, pretende formar profissionais com a visão humanística e
        global da vida social, capacitar os futuros profissionais a intervir e
        tomar decisões para melhorar a qualidade de vida da sociedade e para
        garantir os direitos dos cidadãos, visando construir uma sociedade mais
        justa e igualitária.
        </p>
      </div>
      <Footer/>
    </>
  );
}
