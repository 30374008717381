import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

export default function Farmacia() {
  return (
    <>
      <Header/>
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Farmácia</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <div className='page-image-container'>
          <img src='/assets/cursos/farmacia.webp'/>
        </div>
        <p>Reconhecido: pela Portaria n.º 226 de 29/03/2018.</p>
        <p>
        O egresso do Curso de Farmácia da FACISA será um FARMACÊUTICO com
        formação centrada nos fármacos, nos medicamentos e na assistência
        farmacêutica e, de forma integrada, com formação em análises clínicas e
        toxicológicas, em cosméticos e em alimentos, em prol do cuidado à saúde
        do indivíduo, da família e da comunidade. Para tanto, conta com uma
        infraestrutura moderna, além de um corpo de professores de alta
        titulação com experiência acadêmica e profissional, além de um vasto
        campo de práticas nas quais o acadêmico terá, já nos semestres iniciais,
        contato com situações da vida profissional farmacêutica diferenciando-o
        positivamente no mercado de trabalho.
        </p>
      </div>
      <Footer/>

    </>
  );
}
