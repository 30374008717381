/* eslint-disable react/prop-types */
import React from 'react';
import {BsWhatsapp} from 'react-icons/bs';

export default function CardCurso(props) {
  return (
    <>
      <div className="card-deck">
        <div className="card">
          <img
            className="card-img-top"
            src={props.imagem}
            alt={props.titulo}
          />
          <div className="card-body">
            <h5 className="card-title">{props.titulo}</h5>
            <p className="card-text">{props.desc}</p>
          </div>
          <div className="card-footer">
            <small className="text-muted">
              {props.semestres} Semestres | {props.periodo}
            </small>
            <div className='card-footer-footer'>
              <a
                href={props.linkwhats}
                className="btn btn-primary"
              >
                    Tire suas dúvidas <BsWhatsapp/>
              </a>
              <a
                href="http://189.3.182.158/projetos/concurso/inscricao/index.php?&tid=0&lid=0&&cd_concurso=7&pid=concurso"
                className="btn btn-primary"
              >
                    Inscreva-se
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
