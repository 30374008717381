import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

export default function Enfermagem() {
  return (
    <>
      <Header/>
      <div className='page-wrap'>
        <div className='page-title-wrap'>
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Enfermagem</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <div className='page-image-container'>
          <img src='/assets/cursos/enfermagem.webp'/>
        </div>
        <p>Reconhecido: pela Portaria n.º 351 de 17 de março de 2009</p>
        <p>
        O Curso apresenta na sua estrutura curricular disciplinas fundamentais e
        profissionalizantes que objetivam formar o profissional enfermeiro com
        uma visão crítica e reflexiva sobre o contexto hitórico-social do
        indivíduo e da população na perspectiva da consolidação do Sistema Único
        de Saúde. O Centro de Ensino Superior do Extremo Sul da Bahia propõe,
        através do Curso de Enfermagem, garantir e aperfeiçoar por meio do
        processo ensino-aprendizagem a formação de profissionais comprometidos
        com a melhoria das condições de vida da população, com capacidade para
        se inserir em um mercado de trabalho em franca expansão e que apresente
        compreensão holística e dinâmica do ser humano, com uma competente
        intervenção técnico-científica de posicionamento ético-político.
        Profissional indispensável à promoção da saúde, atua não apenas sob a
        forma do cuidado individualizado, mas numa perspectiva centrada em
        contextos, grupos, práticas educativas e mudanças da realidade social.
        </p>
      </div>
      <Footer/>

    </>
  );
}
