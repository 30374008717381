import React from 'react';
import './Ntcc.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Ntcc() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>NTCC</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <p className='center-text'>Núcleo de trabalho de conclusão de curso.</p>
        <p>
          O Trabalho de Conclusão de Curso (TCC) é obrigatório quando exigido
          nas diretrizes curriculares nacionais, fixadas pelo MEC. Cada IES
          pode, contudo, inserir no currículo de qualquer de seus cursos de
          graduação a referida atividade como obrigatória e indispensável à
          obtenção de diploma. Pode ser desenvolvido sob diversas formas:
          monografia, artigos, relatórios, estudos de caso, projeto experimental
          e trabalhos acadêmico-científicos diversos. Será obrigatoriamente
          individual quando exigido nas diretrizes curriculares nacionais,
          fixadas pelo MEC. Quando não houver essa exigência, poderá ser
          realizado em dupla ou grupo. Não há nenhuma norma, expedida pelo MEC,
          estabelecendo as regras para TCC ou similar.
        </p>
        <p> Cada IES tem autonomia de
          fixar as normas próprias, obedecidas as diretrizes curriculares
          nacionais. A disciplina TCC consiste na elaboração de trabalho final
          de graduação, abordando temas relacionados às disciplinas do Curso, a
          ser elaborado pelo acadêmico em forma de monografia, sob a orientação
          de um professor e aprovado por uma Banca Examinadora. Em cada semestre
          em que a disciplina de TCC for oferecida, o acadêmico será avaliado e
          receberá uma nota, observadas as normas da Instituição quanto à nota
          mínima para aprovação. O TCC além da finalidade regimental de
          integralizar o currículo pleno do Curso tem como objetivos gerais:
          dinamizar as atividades acadêmicas; estimular a iniciação científica;
          desenvolver atividades de pesquisa e extensão; demonstrar a habilidade
          adquirida durante o Curso; aprimorar a capacidade de interpretação e
          crítica bibliográfica.</p>
      </div>

      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Documentos Necessários:</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
      </div>

      <div className='ntcc-document-container'>
        <div>
          <p>Ficha de Acompanhamento de Orientacao</p>
          <a href="/assets/ficha_acompanhamento.pdf">Clique para visualizar</a>
        </div>
        <div>
          <p>Formulário para Solicitacao de Orientador</p>
          <a href="/assets/formulario_solicitacao.pdf">
            Clique para visualizar</a>
        </div>

        <div>
          <p>Artigo FACISA 2022</p>
          <a href="/assets/artigo_facisa.pdf">Clique para visualizar</a>
        </div>

        <div>
          <p>Regulamento TCC FACISA 2022</p>
          <a href="/assets/regulamento_tcc.pdf">Clique para visualizar</a>
        </div>

        <div>
          <p>Termo de aceite TCC</p>
          <a href="/assets/termo_tcc.pdf">Clique para visualizar</a>
        </div>

        <div>
          <p>Manual artigo FACISA 2022</p>
          <a href="/assets/manual_artigo.pdf">Clique para visualizar</a>
        </div>
      </div>


      <Footer />
    </>
  );
}
