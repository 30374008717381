import React from 'react';
import './Header.css';
import {FaFacebook} from 'react-icons/fa';
import {FaInstagramSquare} from 'react-icons/fa';
import {FaWhatsappSquare} from 'react-icons/fa';
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';

export default function Header() {
  return (
    <header className="fixed-header">
      <div className="header">
        <a href="/" className="logo_header">
          <img
            src="./assets/images/logo_facisa.png"
            className="logo"
            alt=""
          ></img>
        </a>
        <div className="header-right">
          <a href="https://www.facebook.com/facisabaonline/?hc_ref=ARSL_Dux7gc1fADrbGTIkScnq7wvZactGTxRD6kU4EfRdIMAqhfUrSQlacKwOn991ZE&fref=nf&__tn__=kC-R">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/facisabaonline/">
            <FaInstagramSquare />
          </a>
          <a href="https://api.whatsapp.com/send/?phone=557388665337&text&type=phone_number&app_absent=0">
            <FaWhatsappSquare />
          </a>
        </div>
      </div>

      <div>
        <Navbar bg="light" expand="lg" sticky="top">
          <Container fluid className="nav-container">
            <Navbar.Brand href="/">
              <img
                alt=""
                src="/assets/images/logo.png"
                width="100"
                height="40"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              className="navbar-light"
            />
            <Navbar.Collapse id="navbarScroll" className="btn-group">
              <Nav className="me-auto my-2 my-lg-0 nav-wrapper" navbarScroll>
                <Nav.Link href="/">INÍCIO</Nav.Link>
                <img
                  src="./assets/svg/caret-down-solid.svg"
                  className="menu-arrow"
                ></img>
                <div className="vr"></div>
                <NavDropdown
                  title="VESTIBULAR"
                  id="navbarScrollingDropdown"
                  renderMenuOnMount={true}
                >
                  <NavDropdown.Item href="/Edital">
                    Edital
                  </NavDropdown.Item>
                  <NavDropdown.Item href="http://cesesb.com/projetos/concurso/inscricao/index.php?&tid=0&lid=0&&pid=concurso">
                    Inscreva-se
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/CursosOferecidos">
                    Cursos Oferecidos
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/Ajuda">
                    Página de Ajuda
                  </NavDropdown.Item>
                </NavDropdown>
                <img
                  src="./assets/svg/caret-down-solid.svg"
                  className="menu-arrow"
                ></img>
                <div className="vr"></div>

                {/* FACISA */}
                <NavDropdown
                  title="FACISA"
                  id="navbarScrollingDropdown"
                  renderMenuOnMount={true}
                >
                  <NavDropdown.Item href="/institucional">
                    Institucional
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/visao">
                    Visão | Missão
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/localizacao">
                    Localização
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/estrutura">
                    Estrutura Física
                  </NavDropdown.Item>
                </NavDropdown>
                <img
                  src="./assets/svg/caret-down-solid.svg"
                  className="menu-arrow"
                ></img>
                <div className="vr"></div>

                {/* ACADEMICO */}
                <NavDropdown
                  renderMenuOnMount={true}
                  title="ACADÊMICO"
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="/secretaria">
                    Secretaria
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/nap">NAP</NavDropdown.Item>
                  <NavDropdown.Item href="/ceam">CEAM</NavDropdown.Item>
                  <NavDropdown.Item href="/laboratorios">
                    Laboratórios
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/npj">NPJ</NavDropdown.Item>
                  <NavDropdown.Item href="/empresa">
                    Empresa Júnior
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/ambulatorio">
                    Ambulatório Escolar
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/regulamentos">
                    Regulamentos
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/cpa">CPA</NavDropdown.Item>
                  <NavDropdown.Item href="/pdi">PDI</NavDropdown.Item>
                  <NavDropdown.Item href="/ppc">PPC</NavDropdown.Item>
                  <NavDropdown.Item href="/ntcc">NTCC</NavDropdown.Item>
                  <NavDropdown.Item href="/calendario">
                    Calendário Acadêmico
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/revista">
                    Revista Acadêmica
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://docs.google.com/forms/d/e/1FAIpQLSe2izP0VJjNqkDPaoBscRM3F0s0Elvmjmh2s4zdHpdXfniuqw/viewform">
                    Egresso
                  </NavDropdown.Item>
                </NavDropdown>
                <img
                  src="./assets/svg/caret-down-solid.svg"
                  className="menu-arrow"
                ></img>
                <div className="vr"></div>

                {/* BIBLIOTECA */}
                <NavDropdown
                  renderMenuOnMount={true}
                  title="BIBLIOTECA"
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="/biblioteca" target="_blank">
                    Apresentação
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://portal.dli.minhabiblioteca.com.br/Login.aspx?key=FACISA" target="_blank">
                    Biblioteca Virtual - FACISA
                  </NavDropdown.Item>
                  <NavDropdown.Item href="http://www.dominiopublico.gov.br/pesquisa/PesquisaObraForm.jsp" target="_blank">
                    Biblioteca Virtual - GRATUITA
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://plataforma.bvirtual.com.br/Account/Login?redirectUrl=%2F" target="_blank">
                    Biblioteca Virtual - PEARSON
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/assets/desenvolvimento_colecoes.pdf"
                    target="_blank">
                    Desenvolvimento de Coleções
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/periodicos" target="_blank">
                    Periódicos
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/repositorio" target="_blank">
                    Repositório TCC
                  </NavDropdown.Item>
                </NavDropdown>
                <img
                  src="./assets/svg/caret-down-solid.svg"
                  className="menu-arrow"
                ></img>
                <div className="vr"></div>

                {/* CURSOS */}
                <NavDropdown
                  renderMenuOnMount={true}
                  title="CURSOS"
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="/administracao">
                    Administração
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/direito">Direito</NavDropdown.Item>
                  <NavDropdown.Item href="/enfermagem">
                    Enfermagem
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/farmacia">Farmácia</NavDropdown.Item>
                  <NavDropdown.Item href="/gastronomia">
                    Gastronomia
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/medicinavet">
                    Medicina Veterinária
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/odontologia">
                    Odontologia
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/radiologia">
                    Radiologia
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/cursos-pos-graduacao">
                    Pós-Graduação
                  </NavDropdown.Item>
                </NavDropdown>
                <img
                  src="./assets/svg/caret-down-solid.svg"
                  className="menu-arrow"
                ></img>
                <div className="vr"></div>

                {/* INTRANET */}
                <NavDropdown
                  renderMenuOnMount={true}
                  title="INTRANET"
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="http://cesesb.com/projetos/portal_online">
                    Portal Unimestre
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/requerimento">
                    Requerimentos
                  </NavDropdown.Item>
                </NavDropdown>
                <img
                  src="./assets/svg/caret-down-solid.svg"
                  className="menu-arrow"
                ></img>
                <div className="vr"></div>

                {/* CONTATO */}
                <NavDropdown
                  renderMenuOnMount={true}
                  title="CONTATO"
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="/ouvidoria">
                    Ouvidoria
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/Recrutamento">
                    Recrutamento
                  </NavDropdown.Item>

                  {/* TOP SIDE */}
                </NavDropdown>
                <img
                  src="./assets/svg/caret-down-solid.svg"
                  className="menu-arrow"
                ></img>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
}
