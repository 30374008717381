import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Visao() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1> Visão </h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          Desse modo, a FACISA tem como visão tornar-se um centro de referência
          entre as instituições de Ensino Superior no Estado da Bahia,
          reconhecido na sociedade itamarajuense e brasileira pelo referencial
          na formação dos profissionais, buscando tornar-se um Centro de
          Excelência em Educação Superior, nos próximos 10 anos. Assim,
          reconhecida como uma instituição de Ensino Superior de excelência, no
          desenvolvimento do ensino, no incentivo e prática da pesquisa e
          extensão e na gestão de projetos sociais, com responsabilidade social.
        </p>
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Missão</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          Desenvolver de forma articulada, integrada e planejada, a Educação
          Superior, com objetivo de educar, produzir e socializar o
          conhecimento, valorizando a integração ensino, pesquisa e extensão
          voltadas para questões nacionais, regionais e local, com
          responsabilidade social, através da promoção e estímulo à formação e
          aperfeiçoamento acadêmico, científico e tecnológico de recursos
          humanos em consonância com os anseios, as necessidades e as
          diversidades da sociedade em geral, ofertando cursos nas áreas de
          Ciências Sociais Aplicadas, Ciências Humanas e Saúde. Além disso,
          incentivar o intercâmbio com instituições de ensino, de pesquisa e de
          cultura, no país e no exterior.
        </p>
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Finalidade</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          Desenvolver de forma articulada, integrada e planejada, a educação
          superior, com objetivo de educar, produzir e socializar o
          conhecimento, valorizando a integração ensino, pesquisa e extensão
          voltadas para questões nacionais, regionais e local, com
          responsabilidade social, através da promoção e estímulo à formação e
          aperfeiçoamento acadêmico, científico e tecnológico de recursos
          humanos em consonância com os anseios, as necessidades e as
          diversidades da sociedade em geral, ofertando cursos nas áreas de
          Ciências Sociais Aplicadas, Ciências Humanas e Saúde. Além disso,
          incentivar o intercâmbio com instituições de ensino, de pesquisa e de
          cultura, no país e no exterior.
        </p>
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Valores</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          Ética; Compromisso; Dedicação; Cumplicidade Institucional e
          Cordialidade.
        </p>
      </div>
      <Footer />
    </>
  );
}
