import React, {useState} from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Repositorio.css';
import '../../filecard.css';

export default function Repositorio() {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMonografias, setFilteredMonografias] = useState([]);

  const monografias = [
    {
      title:
        'A Contribuição da Melhoria Como Instrumento de Viabilidade para o ' +
        'Desenvolvimento Urbano e Inserção Social nos Municípios do Interior ' +
        'da Bahia',
      author: 'Fabrício Ghil Frieber',
      link: '/assets/monografias/direito/FABRÍCIO GHIL FRIEBER.pdf',
    },
    {
      title: 'Adoção Homoafetiva',
      author: 'Joana Angélica Sampaio',
      link: '/assets/monografias/direito/JOANA ANGÉLICA'+
      ' SAMPAIO SARMENTO MENEZES.pdf',
    },
    {
      title:
        'Inseminação Heteróloga: Do anonimato dos doadores de sêmen à ' +
        'filiação socioafetiva',
      author: 'Lidiane Barros Monfardine',
      link: '/assets/monografias/direito/LIDIANI BARROS MONFARDINE.pdf',
    },
    {
      title:
        'O Direito ao Ensino Fundamental e sua Relação com a Cidadania no ' +
        'Município de Itamaraju-BA',
      author: 'Mailton Vieira Franco',
      link: '/assets/monografias/direito/MAILTON VIEIRA FRANCO.pdf',
    },
    {
      title:
        'O Tribunal Penal Internacional: aspectos históricos e desafios ' +
        'atuais face à ordem jurídica brasileira',
      author: 'Mariela de Mattos Moreau',
      link: '/assets/monografias/direito/MARIELA DE MATTOS MOREAU.pdf',
    },
    {
      title: 'Licitação: Transparência na Gestão Pública',
      author: 'Marli Oliveira de Jesus',
      link: '/assets/monografias/direito/MARLI OLIVEIRA DE JESUS.pdf',
    },
    {
      title: 'Controle Social da Administração Pública',
      author: 'Milton Ferreira Guimarães',
      link: '/assets/monografias/direito/MILTON FERREIRA GUIMARÃES.pdf',
    },
    {
      title: 'A desapropriação Urbanística e o Plano Diretor',
      author: 'Nayanna Vasconcelos Lima',
      link: '/assets/monografias/direito/NAYANNA VASCONCELOS LIMA.pdf',
    },
    {
      title: 'O Instituto do Livramento Condicional',
      author: 'Nilson André Cerqueira Menezes',
      link: '/assets/monografias/direito/NILSON ANDRÉ CERQUEIRA MENEZES.pdf',
    },
  ];

  const handleSearch = () => {
    const filtered = monografias.filter((monografia) =>
      monografia.title.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredMonografias(filtered);
  };
  const handleKeyPress = (e) => {
    // Verifica se a tecla pressionada é "Enter" (código 13)
    if (e.key === 'Enter') {
      handleSearch(); // Chama a função handleSearch quando Enter é pressionado
    }
  };

  return (
    <>
      <Header />
      <div className="title-page">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>REPOSITÓRIO TCC</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
      </div>

      <div className="seach col-sm-12 col-md-6 col-md-offset-3">
        <div className="input-group text-center">
          <input
            type="text"
            className="form-control"
            id="pesquisar"
            placeholder="O que você procura?"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          <span className="input-group-btn">
            <button type="button" className="btn-search" onClick={handleSearch}>
              <i className="fa btn-pesquisa">Pesquisar</i>
            </button>
          </span>
        </div>
      </div>

      <div className="title-curso">
        <h2>Direito</h2>
      </div>

      <div className="file-card-container">
        {filteredMonografias.length > 0 ? (
          filteredMonografias.map((monografia, index) => (
            <div className="file-card" key={index}>
              {/* Renderizar informações da monografia */}
              <h1 className="file-card-title">{monografia.title}</h1>
              <p className="file-card-subtitle">{monografia.author}</p>
              <div className="file-card-divison"></div>
              <a
                href={monografia.link}
                className="file-card-link  file-card-link-custom"
              >
                <p className="link-abrir">Abrir</p>
              </a>
            </div>
          ))
        ) : (
          monografias.map((monografia, index) => (
            <div className="file-card" key={index}>
              {/* Renderizar informações da monografia */}
              <h1 className="file-card-title">{monografia.title}</h1>
              <p className="file-card-subtitle">{monografia.author}</p>
              <div className="file-card-divison"></div>
              <a
                href={monografia.link}
                className="file-card-link  file-card-link-custom"
                target="_blank" rel="noopener noreferrer"
              >
                <p className="link-abrir">Abrir</p>
              </a>
            </div>
          ))
        )}
      </div>
      <Footer />
    </>
  );
}
