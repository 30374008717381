import React, {useEffect} from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Periodicos.css';

export default function Repositorio() {
  const monografias = [
    {
      image: '/assets/images/bvs_color.png',
      author: 'Bireme Biblioteca Virtual em Saúde',
      link: 'https://bvsalud.org/',
    },
    {
      image: '/assets/images/Logo-brapci.png',
      author: 'BRAPCI',
      link: 'https://www.brapci.inf.br/#/',
    },
    {
      image: '/assets/images/doaj-logo.png',
      author: 'Directory of Open Access Journals',
      link: 'https://www.doaj.org/',
    },
    {
      image: '/assets/images/hisa_logo.jpg',
      author: 'Base HISA',
      link: 'http://basehisa.coc.fiocruz.br/cgi-bin/wxis.exe/?IsisScript=iah/iah.xis&base=HISA&lang=p',
    },
    {
      image: '/assets/images/livre_logo.png',
      author: 'LivRe-Revistas de livre acesso',
      link: 'https://livre.cnen.gov.br/Inicial.asp',
    },
    {
      image: '/assets/images/medline_logo.jpg',
      author: 'Medline',
      link: 'https://pesquisa.bvsalud.org/portal/?lang=pt&fb=&q=&skfp=true&filter%5Bdb%5D%5B%5D=MEDLINE',
    },
    {
      image: '/assets/images/periodicos_capes_logo.jpg',
      author: 'Periódicos CAPES',
      link: 'https://www-periodicos-capes-gov-br.ezl.periodicos.capes.gov.br/index.php?',
    },
    {
      image: '/assets/images/pubMed_logo.png',
      author: 'PubMed',
      link: 'https://pubmed.ncbi.nlm.nih.gov/',
    },
    {
      image: '/assets/images/scielo_logo.png',
      author: 'Scielo',
      link: 'https://www.scielo.br/',
    },
    {
      image: '/assets/images/sumarios_logo.png',
      author: 'Sumários de Revistas Brasileiras',
      link: 'https://sumarios.org/',
    },
  ];
  useEffect(() => {
    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById('btnTopo').style.display = 'block';
      } else {
        document.getElementById('btnTopo').style.display = 'none';
      }
    }

    window.onscroll = function() {
      scrollFunction();
    };

    // Limpando o event listener quando o componente é desmontado
    return () => {
      window.onscroll = null;
    };
  }, []);

  function topFunction() {
    document.body.scrollTop = 0; // Para browsers que suportam scrollTop
    document.documentElement.scrollTop = 0; // Para browsers modernos
  }

  const linkbireme = () => {
    window.open('https://bvsalud.org/searchtutorial/', '_blank');
  };

  const linkbrapci = () => {
    window.open('https://brapci.inf.br/index.php/res/help', '_blank');
  };

  const linkdoaj = () => {
    window.open('https://www.doaj.org/docs/faq/', '_blank');
  };

  const linkhisa = () => {
    alert('Informações sobre o uso da HISA diretamente' +
        ' na página de pesquisa da base. É permitido' +
        'usar truncagem para pesquisas de palavras com' +
        'o mesmo radical, selecionar o idioma para a pesquisa' +
        'e usar pesquisa avançada ou pesquisa livre.');
  };

  const linklivre = () => {
    alert('Acesso através de listagem em ordem alfabética,' +
        'busca por filtros nas áreas do conhecimento, filtragem' +
        'de inclusões recentes, busca avançada e busca simples.' +
        'Site disponível em português, inglês, espanhol,' +
        'francês, italiano e alemão.');
  };

  const linkmedline = () => {
    alert('A MEDLINE permite o uso de filtros para refinar os' +
        'resultados das buscas. Os filtros disponíveis são: idioma,' +
        'tipo de estudo, assunto principal e intervalo de publicação.' +
        'Além dos filtros, também é possível visualizar o resumo dos' +
        'artigos já nos resultados da busca. Estão disponíveis busca ' +
        'simples e busca avançada. Idiomas: português, inglês, ' +
        'espanhol e francês.');
  };

  const linkacessibilidadecapes = () => {
    window.open('https://www.gov.br/governodigital/pt-br/acessibilidade-digital', '_blank');
  };

  const linkinstrucoescapes = () => {
    window.open('/assets/instrucoes_capes.pdf', '_blank');
  };

  const linktreinamentoscapes = () => {
    window.open('https://www-periodicos-capes-gov-br.ez24.periodicos.capes.gov.br/index.php/treinamentos/calend%C3%A1rio.html', '_blank');
  };

  const linkpubmed = () => {
    window.open('https://pubmed.ncbi.nlm.nih.gov/help/', '_blank');
  };

  const linkScielo = () => {
    alert('A Scielo permite busca por todos os índices ou separando por ano,'+
    ' autor, financiador, periódico, resumo e título. É possível combinar'+
    ' campos através do botão “adicionar outro campo” e usar operadores' +
    ' booleanos para refinar a busca e obter resultados mais precisos.' +
    ' Também é possível acessar os periódicos por tema e por lista alfabética');
  };

  return (
    <>
      <Header />
      <div className='title-page'>
        <div className='page-title-wrap'>
          <img src='https://i.imgur.com/8n3nyYR.png' alt='Logo' />
          <h1>Periódicos</h1>
          <img src='https://i.imgur.com/8n3nyYR.png' alt='Logo' />
        </div>
      </div>

      <div className='subtitle-periodicos'>
        <p>Conheça a coleção de periódicos da FACISA:</p>
      </div>

      <div className='file-card-container'>
        {monografias.map((monografia, index) => (
          <div className='file-card-repositorio' key={index}>
            {/* Renderizar informações da monografia */}
            <img className='image-card' src={monografia.image} />
            <p className='file-card-subtitle'>{monografia.author}</p>
            <div className='file-card-divison'></div>
            <a
              href={monografia.link}
              className='file-card-link file-card-link-custom'
              target='_blank'
              rel='noopener noreferrer'
            >
              <p className='link-abrir'>Abrir</p>
            </a>
          </div>
        ))}
      </div>
      <div id='bireme' className='info-periodicos-container'>
        <div>
          <div className='info-periodicos'>
            <h2>Bireme: Bilioteca Virtual em Saúde</h2>
          </div>
          <img
            className='image-info-periodicos'
            src='/assets/images/bvs_color.png'
            alt='logo da bvs color'
          />
          <div className='text-info-periodicos'>
            <div className='text-periodico'>
              <p>
                <b>A Biblioteca Virtual em Saúde (BVS)</b> é uma rede de fontes
                de informação em saúde que abrange diversos países da América
                Latina e do Caribe. A BVS oferece acesso gratuito e integrado a
                bases de dados, periódicos, livros, teses, mapas, eventos e
                outros recursos relacionados à saúde pública, pesquisa e
                educação.
              </p>
            </div>
            <div>
              <p>
                <b>Assuntos:</b> Saúde; Saúde Mental; Saúde Pública; Educação;
                Sociologia; Ciência e tecnologia; Doenças Crônicas; Psicologia;
                Responsabilidade Social;
              </p>
              <p>
                <b>Acesso:</b> gratuito.
              </p>
              <p>
                <b>Idiomas:</b> português, espanhol e inglês.
              </p>
            </div>
          </div>
          <button
            onClick={linkbireme}
            className='linktutor'
            title='Tutorial de Uso'
          >
            Tutorial de Uso
          </button>
          <button
            onClick={topFunction}
            className='btntopo'
            title='Voltar ao Topo'
          >
            Voltar ao topo
          </button>
        </div>
      </div>
      <div className='info-periodicos-container'>
        <div>
          <div className='info-periodicos'>
            <h2>Bireme: Bilioteca Virtual em Saúde</h2>
          </div>
          <img
            className='image-info-periodicos'
            src='/assets/images/Logo-brapci.png'
            alt='logo da bvs color'
          />
          <div className='text-info-periodicos'>
            <div className='text-periodico'>
              <p>
                A plataforma <b>Brapci</b> é um serviço de indexação e acesso a
                artigos de periódicos em Ciência da Informação publicados no
                Brasil. Ela oferece uma interface simples e intuitiva para
                pesquisar, visualizar e baixar os textos completos dos artigos.
                A plataforma também conta com recursos como estatísticas,
                alertas, citações e exportação de dados. A Brapci é uma
                iniciativa da Universidade Federal do Paraná e conta com o apoio
                de diversas instituições e associações da área.
              </p>
            </div>
            <div>
              <p>
                <b>Assuntos:</b> Ciência da Informação; Biblioteconomia;
                Arquivologia
              </p>
              <p>
                <b>Formato:</b> pdf
              </p>
              <p>
                <b>Acesso:</b> gratuito.
              </p>
              <p>
                <b>Idioma:</b> português
              </p>
            </div>
          </div>
          <button
            onClick={linkbrapci}
            className='linktutor'
            title='Tutorial de Uso'
          >
            Tutorial de Uso
          </button>
          <button
            onClick={topFunction}
            className='btntopo'
            title='Voltar ao Topo'
          >
            Voltar ao topo
          </button>
        </div>
      </div>
      <div className='info-periodicos-container'>
        <div>
          <div className='info-periodicos'>
            <h2>Directory of Open Access Journals</h2>
          </div>
          <img
            className='image-info-periodicos'
            src='/assets/images/doaj-logo.png'
            alt='logo da bvs color'
          />
          <div className='text-info-periodicos'>
            <div className='text-periodico'>
              <p>
                A plataforma <b>Directory of Open Access Journals (DOAJ)</b> é
                um recurso online que reúne periódicos científicos de acesso
                aberto de diversas áreas do conhecimento. O objetivo da
                plataforma é promover a visibilidade e a qualidade desses
                periódicos, bem como facilitar o acesso dos pesquisadores e do
                público aos seus conteúdos. A DOAJ conta com mais de 16 mil
                periódicos indexados, provenientes de mais de 130 países, e
                oferece critérios de seleção rigorosos para garantir a
                confiabilidade das publicações.
              </p>
            </div>
            <div>
              <p>
                <b>Assuntos:</b> Multidisciplinar
              </p>
              <p>
                <b>Formato:</b> pdf
              </p>
              <p>
                <b>Acesso:</b> gratuito.
              </p>
              <p>
                <b>Idioma:</b> multilíngue
              </p>
            </div>
          </div>
          <button
            onClick={linkdoaj}
            className='linktutor'
            title='Tutorial de Uso'
          >
            Tutorial de Uso
          </button>
          <button
            onClick={topFunction}
            className='btntopo'
            title='Voltar ao Topo'
          >
            Voltar ao topo
          </button>
        </div>
      </div>
      <div className='info-periodicos-container'>
        <div>
          <div className='info-periodicos'>
            <h2>
              Base Bibliográfica em História da Saúde Pública na América Latina
              e Caribe (Base HISA)
            </h2>
          </div>
          <img
            className='image-info-periodicos'
            src='/assets/images/hisa_logo.jpg'
            alt='logo da bvs color'
          />
          <div className='text-info-periodicos'>
            <div className='text-periodico'>
              <p>
                A
                <b>
                  Base Bibliográfica em História da Saúde Pública na América
                  Latina e Caribe
                </b>
                é um projeto que visa reunir e divulgar fontes documentais sobre
                a história da saúde pública na região. O objetivo é facilitar o
                acesso e a pesquisa de estudiosos, professores, alunos e
                interessados no tema. Conta com mais de 10 mil registros de
                livros, artigos, teses, dissertações e outros materiais, em que
                se destacam documentos sobre eventos e processos históricos como
                objetos de disciplinas das Ciências Humanas e da Saúde.
              </p>
            </div>
            <div>
              <p>
                <b>Assuntos:</b> Políticas de saúde; Medicina tradicional e
                alternativa; Saúde mental; Saúde e meio ambiente; História da
                medicina.
              </p>
              <p>
                <b>Formato:</b> pdf
              </p>
              <p>
                <b>Acesso:</b> gratuito.
              </p>
              <p>
                <b>Idioma:</b> português, espanhol e inglês.
              </p>
            </div>
          </div>
          <button
            onClick={linkhisa}
            className='linktutor'
            title='Tutorial de Uso'
          >
            Tutorial de Uso
          </button>
          <button
            onClick={topFunction}
            className='btntopo'
            title='Voltar ao Topo'
          >
            Voltar ao topo
          </button>
        </div>
      </div>
      <div className='info-periodicos-container'>
        <div>
          <div className='info-periodicos'>
            <h2>LivRe: Revistas de livre acesso</h2>
          </div>
          <img
            className='image-info-periodicos'
            src='/assets/images/livre_logo.png'
            alt='logo da bvs color'
          />
          <div className='text-info-periodicos'>
            <div className='text-periodico'>
              <p>
                O <b>LivRe</b> é o portal de periódicos de acesso aberto mantido
                pelo Centro de Informações Nucleares (CIN), da Comissão de
                Informações Nucleares (CNEN). O acervo do LivRe conta com
                periódicos, revistas de divulgação científica e boletins
                técnicos. O acesso é livre, mas alguns periódicos pedem que o
                usuário se registre para acessar o conteúdo.
              </p>
            </div>
            <div>
              <p>
                <b>Assuntos:</b> multidisciplinar
              </p>
              <p>
                <b>Formato:</b> pdf
              </p>
              <p>
                <b>Acesso:</b> gratuito.
              </p>
              <p>
                <b>Idioma:</b> multilingue.
              </p>
            </div>
          </div>
          <button
            onClick={linklivre}
            className='linktutor'
            title='Tutorial de Uso'
          >
            Tutorial de Uso
          </button>
          <button
            onClick={topFunction}
            className='btntopo'
            title='Voltar ao Topo'
          >
            Voltar ao topo
          </button>
        </div>
      </div>
      <div className='info-periodicos-container'>
        <div>
          <div className='info-periodicos'>
            <h2>MEDLINE</h2>
          </div>
          <img
            className='image-info-periodicos'
            src='/assets/images/medline_logo.jpg'
            alt='logo da bvs color'
          />
          <div className='text-info-periodicos'>
            <div className='text-periodico'>
              <p>
                A <b>MEDLINE</b> contempla diversas áreas da saúde como
                medicina, enfermagem, odontologia, veterinária, medicina
                esportiva, entre outras. A base é gerenciada pela National
                Library of Medicine (NLM) dos Estados Unidos e conta com mais de
                29 milhões de registros disponibilizados em formato XML. A
                MEDLINEpossui uma ferramenta de busca avançada chamada PubMed,
                que permite a busca de referências bibliográficas.
              </p>
            </div>
            <div>
              <p>
                <b>Assuntos:</b> Administração; Biografia; Cultura; Educação;
                Escravidão; Escrita; Ética; História; Sociologia; Linguística;
                Meio Ambiente; Normas Técnicas; Política Internacional;
                Psicologia; Responsabilidade Social; Saúde.
              </p>
              <p>
                <b>Formato:</b> ePub; pdf.
              </p>
              <p>
                <b>Acesso:</b> gratuito.
              </p>
              <p>
                <b>Idioma:</b> inglês, francês, espanhol, alemão.
              </p>
            </div>
          </div>
          <button
            onClick={linkmedline}
            className='linktutor'
            title='Tutorial de Uso'
          >
            Tutorial de Uso
          </button>
          <button
            onClick={topFunction}
            className='btntopo'
            title='Voltar ao Topo'
          >
            Voltar ao topo
          </button>
        </div>
      </div>
      <div className='info-periodicos-container'>
        <div>
          <div className='info-periodicos'>
            <h2>Periódicos CAPES</h2>
          </div>
          <img
            className='image-info-periodicos'
            src='/assets/images/periodicos_capes_logo.jpg'
            alt='logo de periodicos da CAPES'
          />
          <div className='text-info-periodicos'>
            <div className='text-periodico'>
              <p>
                O <b>Portal de Periódicos Capes</b> é uma base de dados
                brasileira mantida pela Coordenação de Aperfeiçoamento de
                Pessoal de Nível Superior (Capes), que reúne diversas bases de
                dados nacionais e internacionais, incluindo a MEDLINE, SciELO e
                Web of Science. A base conta com mais de 38 mil periódicos, além
                de livros, teses e dissertações. Os registros são
                disponibilizados em diversos formatos, como PDF, HTML e XML.
              </p>
            </div>
            <div>
              <p>
                <b>Assuntos:</b> Administração; Biografia; Cultura; Educação;
                Escravidão; Escrita; Ética; História; Sociologia; Linguística;
                Meio Ambiente; Normas Técnicas; Política Internacional;
                Psicologia; Responsabilidade Social; Saúde.
              </p>
              <p>
                <b>Instruções de uso:</b> A CAPES disponibiliza uma listagem com
                os periódicos disponíveis para acesso. É possível acessar por
                ordem alfabética, pesquisa por área do conhecimento, título,
                fornecedor/editor.
              </p>
              <p>
                O site conta com um link de ajuda na página
                <a
                  style={{textDecoration: 'none'}}
                  href='https://www-periodicos-capes-gov-br.ezl.periodicos.capes.gov.br/index.php/acervo/lista-a-z-bases.html'
                >
                  {' '}
                  “Lista de bases e coleções”
                </a>{' '}
                que explica todos os métodos de buscas.
              </p>
              <p>
                <b>APP Portal de Treinamentos do Periodicos da CAPES:</b> Acesse
                as lojas virtuais e baixe o aplicativo <b>.periodicos.</b> para
                facilitar suas pesquisas.
              </p>
              <p>
                Disponível na{' '}
                <a
                  style={{textDecoration: 'none'}}
                  href='https://apps.apple.com/br/app/periodicos/id719655314'
                >
                  APP Store
                </a>
              </p>
              <p>
                Disponível na{' '}
                <a
                  style={{textDecoration: 'none'}}
                  href='https://play.google.com/store/apps/details?id=br.gov.capes.periodicos&hl=pt_BR&pli=1'
                >
                  Play Store
                </a>
              </p>
            </div>
          </div>
          <button
            onClick={linkacessibilidadecapes}
            className='linktutor'
            title='Acessibilidade'
          >
            Acessibilidade
          </button>
          <button
            onClick={linkinstrucoescapes}
            className='linktutor'
            title='Instruções de Acesso'
          >
            Instruções de Acesso
          </button>
          <button
            onClick={linktreinamentoscapes}
            className='linktutor'
            title='Calendário de Treinamentos do Periódicos da CAPES'
          >
            Calendário de Treinamentos do Periódicos da CAPES
          </button>
          <button
            onClick={topFunction}
            className='btntopo'
            title='Voltar ao Topo'
          >
            Voltar ao topo
          </button>
        </div>
      </div>
      <div className='info-periodicos-container'>
        <div>
          <div className='info-periodicos'>
            <h2>PubMED</h2>
          </div>
          <img
            className='image-info-periodicos'
            src='/assets/images/pubMed_logo.png'
            alt='logo da pubMED'
          />
          <div className='text-info-periodicos'>
            <div className='text-periodico'>
              <p>
                <b>PubMed</b> é uma das maiores bases de dados na área de
                Ciências da Saúde, com mais de 30 milhões de registros. A base é
                gerenciada pela National Library of Medicine (NLM) dos Estados
                Unidos e conta com referências bibliográficas de artigos
                científicos, livros, teses e dissertações. Os registros são
                disponibilizados em formato XML e a base está disponível em
                diversas línguas, incluindo inglês, espanhol, francês e alemão.
              </p>
            </div>
            <div>
              <p>
                <b>Assuntos:</b> Biomedicina, Medicina.
              </p>
              <p>
                <b>Formato</b> Resumos e citações.
              </p>
              <p>
                <b>Acesso:</b> Gratuito.
              </p>
              <p>
                <b>Idioma:</b> Multilingue.
              </p>
              <p>
                <b>Istruções de Acesso</b>
              </p>
              <p>
                <p>Para fazer o 1º login:</p>
                <p>
                  {'-'} Clicar em “login” e escolher uma forma de acesso <br />
                  {'-'} Na página que abrir, conferir o endereço de e-mail e
                  clicar <br />
                  em “Create new account”
                </p>
                <p>
                  2º acesso em diante <br />
                </p>
                <p>
                  {'-'} Clicar em login <br />
                  {'-'} Escolher a forma de login <br />
                </p>
                <p>
                  O acesso aos artigos pode ser feito sem login, mas com a conta
                  é possível acessar o painel para gerenciamento de
                  bibliografias, coleções, buscas salvas, entre outros. Mais
                  informações no vídeo (áudio e legendas em inglês) de
                  apresentação do MY NCBI no canal da National Library of
                  Medicine.
                </p>
              </p>
            </div>
          </div>
          <button
            onClick={linkpubmed}
            className='linktutor'
            title='Instrucoes de uso'
          >
            Instruções de uso
          </button>
          <button
            onClick={topFunction}
            className='btntopo'
            title='Voltar ao Topo'
          >
            Voltar ao topo
          </button>
        </div>
      </div>
      <div className='info-periodicos-container'>
        <div>
          <div className='info-periodicos'>
            <h2>Scielo</h2>
          </div>
          <img
            className='image-info-periodicos'
            src='/assets/images/scielo_logo.png'
            alt='logo Scielo'
          />
          <div className='text-info-periodicos'>
            <div className='text-periodico'>
              <p>
                A Scientific Electronic Library Online, ou simplesmente{' '}
                <b>Scielo</b>, é uma biblioteca eletrônica que oferece acesso
                gratuito a uma ampla variedade de periódicos científicos da
                América Latina, Caribe, Portugal, Espanha e África do Sul. A
                Scielo tem como objetivo contribuir para a democratização do
                conhecimento científico, oferecendo acesso aberto à produção
                acadêmica desses países e regiões, muitas vezes inacessível aos
                pesquisadores devido às barreiras financeiras e linguísticas.
                Atualmente, a Scielo possui uma coleção de mais de 1.600
                periódicos científicos de diversas áreas do conhecimento, como
                ciências da saúde, ciências exatas e da terra, ciências sociais,
                humanidades, entre outras.
              </p>
            </div>
            <div>
              <p>
                <b>Assuntos:</b> Biomedicina, Medicina.
              </p>
              <p>
                <b>Formato</b> Artigos completos e resumos.
              </p>
              <p>
                <b>Acesso:</b> Gratuito.
              </p>
              <p>
                <b>Idioma:</b> Multilingue.
              </p>
            </div>
          </div>
          <button
            onClick={linkScielo}
            className='linktutor'
            title='Instrucoes de uso'
          >
            Instruções de uso
          </button>
          <button
            onClick={topFunction}
            className='btntopo'
            title='Voltar ao Topo'
          >
            Voltar ao topo
          </button>
        </div>
      </div>
      <div className='info-periodicos-container'>
        <div>
          <div className='info-periodicos'>
            <h2>Scielo</h2>
          </div>
          <img
            className='image-info-periodicos'
            src='/assets/images/sumarios_logo.png'
            alt='logo Sumarios'
          />
          <div className='text-info-periodicos'>
            <div className='text-periodico'>
              <p>
                <b>Sumários de Revistas Brasileiras</b> é uma base indexadora de
                periódicos científicos brasileiros, disponibilizando links para
                revistas completas e artigos. Resultado da retomada da série
                “Sumários Correntes Brasileiros”, pela Fundação de Pesquisas
                Científicas de Ribeirão Preto (FUNPEC-RP), antes desenvolvido
                pelo Instituto Brasileiro de Informação em Ciências e Tecnologia
                (IBICT). O Sumários.org indexa títulos em diversos idiomas.
              </p>
            </div>
            <div>
              <p>
                <b>Assuntos:</b> Multidisciplinar.
              </p>
              <p>
                <b>Formato:</b> PDF.
              </p>
              <p>
                <b>Acesso:</b> Gratuito.
              </p>
              <p>
                <b>Idioma:</b> Português (principal).
              </p>
              <p>
                <b>Informações de Acesso</b> O cadastro na base é necessário
                caso o usuário queira fazer o upload de um periódico e/ou
                sumário. Para utilização e leitura dos textos disponíveis, basta
                executar a busca no campo indicado na página. <br />
                <a
                  style={{textDecoration: 'none'}}
                  href='https://www.youtube.com/watch?v=AI_ZP_DtUIE&ab_channel=SuporteFUNPEC-RP'
                >
                  {' '}
                  Instrução de cadastro no Sumarios.org
                </a>{' '}
              </p>
            </div>
          </div>
          <button
            onClick={topFunction}
            className='btntopo'
            title='Voltar ao Topo'
          >
            Voltar ao topo
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
