import React from 'react';
import './Regulamentos.css';
import './../../filecard.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Regulamentos() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Regulamentos:</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div className="file-card-container">
          <div className="file-card">
            <h1 className="file-card-title"> Regimento FACISA </h1>
            <div className="file-card-divison"></div>
            <a href="/assets/regimento_facisa.pdf" className="file-card-link">
              <img
                src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
                className="pdf-img"
              />
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className="file-card">
            <h1 className="file-card-title"> Regulamento Manual do Aluno </h1>
            <div className="file-card-divison"></div>
            <a
              href="/assets/regulamento_manual_aluno.pdf"
              className="file-card-link"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
                className="pdf-img"
              />
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className="file-card">
            <h1 className="file-card-title"> Regulamento Ouvidoria </h1>
            <div className="file-card-divison"></div>
            <a
              href="/assets/regulamento_ouvidoria.pdf"
              className="file-card-link"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
                className="pdf-img"
              />
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className="file-card">
            <h1 className="file-card-title center-text">
              Regimento Laboratórios de Saúde
            </h1>
            <div className="file-card-divison"></div>
            <a
              href="/assets/regimento_laboratorio.pdf"
              className="file-card-link"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
                className="pdf-img"
              />
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className="file-card">
            <h1 className="file-card-title center-text">
            Regulamento AC
            </h1>
            <div className="file-card-divison"></div>
            <a
              href="/assets/regulamento_ac.pdf"
              className="file-card-link"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
                className="pdf-img"
              />
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className="file-card">
            <h1 className="file-card-title center-text">
            Regulamento Monitoria
            </h1>
            <div className="file-card-divison"></div>
            <a
              href="/assets/regulamento_monitoria.pdf"
              className="file-card-link"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
                className="pdf-img"
              />
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className="file-card">
            <h1 className="file-card-title center-text">
            Regulamento NDE
            </h1>
            <div className="file-card-divison"></div>
            <a
              href="/assets/regulamento_nde.pdf"
              className="file-card-link"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
                className="pdf-img"
              />
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className="file-card">
            <h1 className="file-card-title center-text">
            Regulamento CPA
            </h1>
            <div className="file-card-divison"></div>
            <a
              href="/assets/regulamento_cpa.pdf"
              className="file-card-link"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
                className="pdf-img"
              />
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className="file-card">
            <h1 className="file-card-title center-text">
            Regulamento NAP
            </h1>
            <div className="file-card-divison"></div>
            <a
              href="/assets/regulamento_nap.pdf"
              className="file-card-link"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
                className="pdf-img"
              />
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className="file-card">
            <h1 className="file-card-title center-text">
            Contrato FACISA
            </h1>
            <div className="file-card-divison"></div>
            <a
              href="/assets/contrato_facisa.pdf"
              className="file-card-link"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
                className="pdf-img"
              />
              <p>Abrir o PDF</p>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
