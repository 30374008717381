import React, {useState, useEffect} from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Recrutamento.css';

export default function Recrutamento() {
  const [mostrarConteudo, setMostrarConteudo] = useState(false);

  useEffect(() => {
    // Define mostrarConteudo como true após o DOM ser carregado
    setMostrarConteudo(true);
  }, []); // O segundo argumento vazio faz com que o useEffect
  // seja executado apenas uma vez, após a montagem do componente

  return (
    <>
      <Header />
      <div className={`recrutamento ${mostrarConteudo ? 'mostrar' : ''}`}
        id="conteudo">
        <div className="img-left">
          <img className="recrutamento-img"
            src="/assets/images/mulher-adm.png" alt=""/>
        </div>
        <div className="text-curriculo">
          <p>Cadastre seu <br /> currículo com a <br />FACISA</p>
          <button onClick={() => window.open('https://forms.gle/A8wRRe2ZEeiNuT476', '_blank')}className="botao-curriculo">Cadastre-se</button>
        </div>
      </div>
      <Footer />

    </>
  );
}
