import React, {useEffect, useState} from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Slide from '../../components/Slide/Slide';
import './Home.css';
// Carousel > https://www.npmjs.com/package/react-multi-carousel.
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 4,
  },
  tablet: {
    breakpoint: {max: 1280, min: 464},
    items: 4,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
};


export default function Home() {
  async function fetchData() {
    // eslint-disable-next-line max-len
    const token = 'IGQWROaVZAla1VQWnVZAZAlpJTUE1WDZA4OHFmNDlXRDBBTFRvYWxRUkhWRkE1M3V5UFV4QlAwRTFJRUpFbWs0Tll1ZATRENDlsbFpjZA1lNd3FTZATRUQnVuaWd3TmxvRFQzajE1OWUydkVvTWhRVUZAfMDVwQXo3bjMxYk0ZD';
    const url = `https://graph.instagram.com/me/media?fields=media_url,media_type,permalink&access_token=${token}`;

    const data = await fetch(url);
    const feed = await data.json();
    await setInstagramData(feed.data.filter((el) => el.media_type === 'IMAGE'));
    console.log(feed.data);
  }

  const [instagramData, setInstagramData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  return (<>
    <Header />

    <Slide />

    <div className='page-divider'></div>

    <div className='page-wrap home-news-container'>
      <div className='page-title-wrap'>
        <img src="https://i.imgur.com/8n3nyYR.png" />
        <h1>Notícias</h1>
        <img src="https://i.imgur.com/8n3nyYR.png" />
      </div>
      <Carousel
        swipeable={true}
        draggable={true}
        containerClass="news-carrosel"
        itemClass="home-news-card"
        ssr={true}
        responsive={responsive}
      >
        {
          instagramData.map((post) => (
            <a
              href={post.permalink}
              target="_blank"
              rel="noopener noreferrer"
              key={post.id}>
              <img src={post.media_url} />
            </a>
          ))
        }

      </Carousel>
    </div>

    <div className='page-divider'></div>

    <div className='page-wrap home-brief-container'>
      <div className='page-title-wrap'>
        <img src="https://i.imgur.com/8n3nyYR.png" />
        <h1>Depoimentos</h1>
        <img src="https://i.imgur.com/8n3nyYR.png" />
      </div>
      <p className='center-text'>
        Nossa meta é oferecer sempre o melhor serviço educacional,
        sendo parte da nossa missão formar profissionais críticos,
        responsáveis e comprometidos com o desenvolvimento social e humano.
        Desta forma, temos como objetivo promover o conhecimento com inovação,
        nos limites da ética que contribuam para a consolidação do
        desenvolvimento regional e do nosso país.</p>
      <div className='depoimentos'>
        <div className='depoimento-container'>
          <video width="320" height="240" controls>
            <source src="/assets/depoimento1.mp4" type="video/mp4"/>
          </video>
          <p><b>J. P. Rizzi</b></p>
          <p>Graduado em Administração - Facisa</p>
          <p>Empresário no Ramo Gastronômico</p>
        </div>
        <div className='depoimento-container segundo-depoimento'>
          <video width="320" height="240" controls>
            <source src="/assets/depoimento2.mp4" type="video/mp4"/>
          </video>
          <p><b>D. C. Scopel</b></p>
          <p>Graduada em Administração - Facisa</p>
        </div>
      </div>
    </div>

    <div className='page-divider'></div>

    <div className='page-wrap home-instalations-container'>
      <div className='page-title-wrap home-instalations-title'>
        <img src="https://i.imgur.com/8n3nyYR.png" />
        <h1>Instalações</h1>
        <img src="https://i.imgur.com/8n3nyYR.png" />
      </div>
      <p className='center-text'>
        Venha estudar na melhor Faculdade do Sul e Extremo Sul da Bahia,
        com excelência comprovada pelo MEC. A FACISA possui um campus
        repleto de: laboratórios e salas climatizadas.
        Venha conhecer de perto!</p>
      <div className='instalacoes-container'>
        <div className='instalacoes-list list-left'>
          <p>Salas Climatizadas</p>
          <p>Biblioteca</p>
          <p>Laboratório de T.I</p>
          <p>Estúdio de Filmagens</p>
          <p>Cozinha Profissional totalmente equipada</p>
          <p>Sala de degustação climatizada</p>
        </div>

        <iframe className="video-intalacoes" width="560" height="315"
          src="https://www.youtube.com/embed/0rU8bdP4ylU?autoplay=1&mute=1&loop=1&controls=0">
        </iframe>

        <div className='instalacoes-list list-right'>
          <p>Clínica de Odontologia</p>
          <p>Sala de Anatomia / Microbiologia</p>
          <p>Laboratórios de Radiologia</p>
          <p>Sala de Raios-X</p>
          <p>Auditório com capacidade para 500 pessoas</p>
          <p>Quadra poliesportiva coberta</p>
          <p>Estacionamento</p>
        </div>
      </div>
    </div>

    <Footer />
  </>);
}

