import React from 'react';
import './../../filecard.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Ppc() {
  return (
    <>
      <Header/>
      <div className='page-wrap'>
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Projetos Pedagógicos de Cursos:</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div className='file-card-container'>
          <div className='file-card'>
            <h1 className='file-card-title'> PPC - Serviço Social </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/ppc_servico_social1.pdf'
              className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> PPC - Enfermagem </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/ppc_enfermagem1.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> PPC - Radiologia </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/ppc_radiologia1.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> PPC - Administração </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/ppc_administracao1.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> PPC - Gastronomia </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/ppc_gastronomia1.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> PPC - Veterinária </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/ppc_med_veterinaria1.pdf'
              className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> PPC - Direito </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/ppc_direito1.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> PPC - Odontologia </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/ppc_odontologia.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>

          <div className='file-card'>
            <h1 className='file-card-title'> PPC - Farmácia </h1>
            <div className='file-card-divison'></div>
            <a href='/assets/ppc_farmacia1.pdf' className='file-card-link'>
              <img src='https://cdn-icons-png.flaticon.com/512/80/80942.png' className='pdf-img'/>
              <p>Abrir o PDF</p>
            </a>
          </div>
        </div>

      </div>
      <Footer/>
    </>
  );
}
