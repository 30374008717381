import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

export default function Direito() {
  return (
    <>
      <Header/>
      <div className='page-wrap'>
        <div className='page-title-wrap'>
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Direito</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <div className='page-image-container'>
          <img src='/assets/cursos/direito.jpg'/>
        </div>
        <p>Reconhecido pela Portaria n.º 293 de 23 de junho de 2006</p>
        <p>
        O Curso apresenta uma estrutura curricular avançada que contempla
        disciplinas fundamentais e profissionalizantes voltadas para a formação
        de um profissional com maior senso crítico e consciente de suas
        responsabilidades como operador jurídico. Seu papel é o de capacitar
        pessoas para atuarem na resolução dos mais diversos conflitos, visando
        sempre a integração do homem à sociedade e a valorização do conhecimento
        aplicado à construção de um mundo mais justo. O Profissional em Direito
        possui a Atuação do Profissional: Procuradoria do Estado e da Justiça,
        Auditoria, Assessoria a Empresas, Advocacia, Ministério Público,
        Magistratura.
        </p>
      </div>
      <Footer/>

    </>
  );
}
