import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Estrutura.css';

export default function Estrutura() {
  return (
    <>
      <Header />
      <div className="page-wrap estrutura-page">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Estrutura Física</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          FACISA possui um amplo e moderno espaço para a comodidade de seus
          alunos, professores e para o corpo técnico administrativo, para
          desenvolver o andamento dos trabalhos educativos com qualidade e
          excelência. Para as aulas, contamos com 25 salas climatizadas,
          equipadas com Data Show, DVD, TV e Som. A Biblioteca possui um acervo
          de aproximadamente 23 mil títulos, conta ainda com cabines individuais
          de estudo e sala de estudo em grupo. Na parte gerencial, possui um
          moderno sistema de controle dos livros.Conta ainda com estúdio de
          gravações bem
          equipado com equipamentos de ultima geração. A área da saúde, a FACISA
          possui Laboratórios de Histologia, Citologia, Bioquímica,
          Microbiologia, Parasitologia, Fundamentos de Enfermagem e Anatomia. Os
          acadêmicos de Direito possuem o Núcleo de Prática Jurídica, onde
          exercem o ato jurídico na prática. Os acadêmicos de Administração
          possuem a Empresa Júnior onde os discentes também se familiarizam com
          o meio prático do curso. Os acadêmicos de Serviço Social possuem o
          Centro de Especializado Atendimento à Mulher, onde faz acompanhamento
          com o assistente social.
        </p>
        <iframe className='video-estrutura' width="600" height="406" src="https://www.youtube.com/embed/0rU8bdP4ylU?autoplay=1" title="INSTITUCIONAL FACISA" allow="autoplay" ></iframe>

        <p> O Auditório climatizado possui
          capacidade para mais de 500 pessoas, a brigando grandes seminários,
          palestras, simpósios e mesas redondas, além das já tradicionais
          formaturas das turmas da Instituição Para garantir conexão com o que
          há de mais moderno no mundo tecnológico, contamos com dois links de
          internet de alta velocidade, sendo um deles dedicado exclusivamente
          para o Laboratório de Informática que dá subsídios para que os alunos
          tenham um bom aprendizado extraclasse e as redes de Wi-Fi abertas para
          o acesso individual do acadêmico e também com poderosos servidores de
          rede que permite que a comunicação entre os setores flua com a rapidez
          e qualidade necessária. </p>
        <p> Os acadêmicos de Odontologia, Radiologia e
          Medicina Veterinária possuem avançados Laboratórios e Clínica com
          equipamentos de ponta. Os acadêmicos de Gastronomia, contam com uma
          cozinha profissional super equipada e uma agradável área de
          degustação. Possui uma quadra poliesportiva que integraliza a
          comunidade acadêmica e a comunidade externa. No espaço interno, existe
          uma ampla área de convivência onde os acadêmicos podem interagir e
          trocar experiências dispõe de cantina para alimentação dos alunos. No
          espaço externo, dispõe de um amplo estacionamento. Tudo às margens da
          BR 101, no Km 808, facilitando o trajeto dos alunos às cidades
          circunvizinhas.</p>
      </div>
      <Footer />
    </>
  );
}
