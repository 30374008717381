import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Nap() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>NAP</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          O núcleo de Apoio Psicopedagógico– NAP da FACISA - tem a finalidade de
          proporcionar aos docentes e discentes subsídios, informações e
          assentamento para que possam escolher, entre diversos itinerários e
          opções, aquele, que lhe é mais adequado. Entendida como um projeto
          educacional que tende a proporcionar meios para a formação integral,
          cognitiva, relação intra e interpessoal e inserção profissional e
          social.
        </p>
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>NAP tem como objetivo:</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          Orientar a todos os envolvidos no desenvolvimento do indivíduo
          pessoal, educacional, profissional- visando melhoria da qualidade do
          Ensino, Pesquisa e Extensão. Potencializar e enriquecer o
          desenvolvimento dos indivíduos que integram a instituição educacional,
          sua organização e seu funcionamento. Contribuir para prevenir
          possíveis dificuldades que venham interferir no bom andamento das
          relações pessoal e interpessoal dos docentes, discentes e
          administrativos. Assessorar a prática pedagógica voltada à inovação
          educacional para a qualidade de Ensino, Pesquisa e Extensão da
          Instituição. O NAP funciona toda sexta-feira das 14h às 17h, na
          FACISA. O agendamento deverá ser feito na recepção ou pelo telefone da
          instituição.
        </p>
      </div>
      <Footer />
    </>
  );
}
