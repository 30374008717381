import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

export default function MedicinaVet() {
  return (
    <>
      <Header/>
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Medicina Veterinária</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <div className='page-image-container'>
          <img src='/assets/cursos/medvet.webp'/>
        </div>
        <p>Autorizado pela Portaria Nº 155 de 29 de março de 2019</p>
        <p>
        O curso de Medicina Veterinária da FACISA foi criado com o objetivo de
        formar um bacharel em Medicina Veterinária generalista, crítico e capaz
        de atuar na promoção da saúde e na produção animal, em benefício da
        sociedade. A formação humanista do aluno se integra aos conhecimentos
        nas áreas de clínica e cirurgia, saúde pública, saneamento e
        sustentabilidade ambiental, inspeção e tecnologia de produtos de origem
        animal, responsabilidade técnica de estabelecimentos que comercializem
        produtos de origem animal e na produção animal. O projeto pedagógico
        associa ensino, pesquisa e extensão utilizando o hospital veterinário e
        as fazendas experimentais, localizados na cidade de Itamaraju, além de
        várias visitas técnicas em indústrias, em outras fazendas e em
        comunidades, onde as atividades práticas são desenvolvidas.
        </p>
      </div>
      <Footer/>

    </>
  );
}
