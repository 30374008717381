import React, {useState, useEffect} from 'react';
import {BsWhatsapp} from 'react-icons/bs';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Oferecidos.css';
import CardCurso from '../../components/CardCurso/CardCurso';
import db from '../../assets/db/cursos_db';


export default function Oferecidos() {
  const [mostrarConteudo, setMostrarConteudo] = useState(false);

  useEffect(() => {
    // Define mostrarConteudo como true após o DOM ser carregado
    setMostrarConteudo(true);
  }, []); // O segundo argumento vazio faz com que o useEffect
  // seja executado apenas uma vez, após a montagem do componente
  return (
    <>
      <Header />

      <div className={`title-edital ${mostrarConteudo ? 'mostrar' : ''}`}
        id="conteudo">
        <h1>Cursos Oferecidos pela FACISA</h1>
      </div>
      <div
        className={`page-edital ${mostrarConteudo ? 'mostrar' : ''}`}
        id="conteudo"
      >
        <div className="vestibular-buttons">
          <a
            href="http://cesesb.com/projetos/concurso/inscricao/index.php?&tid=0&lid=0&&pid=concurso"
            className="btn btn-primary"
            id='btn-edital'
          >
            Quero me inscrever
          </a>
          <a
            href="http://cesesb.com/projetos/concurso/inscricao/index.php?&tid=0&lid=0&&pid=concurso"
            className="btn btn-success"
            id='btn-edital'
          >
            Acompanhe sua inscrição
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=557388665337&text&type=phone_number&app_absent=0"
            className="btn btn-success"
            id='btn-edital'
          >
            Tire suas dúvidas pelo whatsapp <BsWhatsapp />
          </a>
        </div>
      </div>
      <div id="cursos-ofer">
        <div className="cursos-container">
          {db.map((curso) => (
            <CardCurso
              key={curso.curso}
              imagem={curso.imagem}
              titulo={curso.curso}
              periodo={curso.periodo}
              semestres={curso.semestres}
              desc={<div className="descricao-limitada">{curso.descricao}</div>}
              linkwhats={curso.linkwhats}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
