import React from 'react';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';

export default function SaudePublica() {
  const marginSection = {marginTop: '1em'};

  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>PÓS GRADUAÇÃO EM SAÚDE PÚBLICA</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <p>
          Este Curso tem o propósito de oferecer elementos teóricos e
          ferramentas necessárias à especialização profissional na área de Saúde
          Pública, contribuindo com a capacitação de profissionais de saúde para
          atuarem em diferentes níveis e Instituições, subsidiando-os para uma
          prática mais resolutiva na atenção à saúde da população assistida,
          como também na reorganização dos serviços de saúde.
        </p>
        <h5 style={marginSection}>Carga Horária Total: 360h</h5>
        <h5 style={marginSection}>
          PÚBLICO ALVO: Graduados com Certificado reconhecido pelo Conselho
          Nacional de Educação
        </h5>
        <h4 style={marginSection}>Grade Curricular</h4>
        <ol>
          <li>Metodologia do Ensino Superior</li>
          <li>Metodologia do Trabalho Científico</li>
          <li>Competências profissionais no Mundo Moderno</li>
          <li>Bioestatística</li>
          <li>História Social e Políticas de Saúde Pública</li>
          <li>Educação para Saúde Pública e Comunitária</li>
          <li>Saúde Pública e Meio Ambiente</li>
          <li>Epidemiologia e Vigilâncias em Saúde</li>
          <li>Planejamento e Gestão em Saúde</li>
          <li>
            Financiamento e Organização do Sistema de Saúde Pública no Brasil
          </li>
          <li>Trabalho de Conclusão de Curso – TCC</li>
        </ol>
      </div>
      <Footer />
    </>
  );
}
