import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Pages from './pages';

export default function Router() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Pages.Home />} />
        <Route path="/institucional" element={<Pages.Institucional />} />
        <Route path="/visao" element={<Pages.Visao />} />
        <Route path="/localizacao" element={<Pages.Localizacao />} />
        <Route path="/estrutura" element={<Pages.Estrutura />} />
        <Route path="/secretaria" element={<Pages.Secretaria />} />
        <Route path="/nap" element={<Pages.Nap />} />
        <Route path="/ceam" element={<Pages.Ceam />} />
        <Route path="/laboratorios" element={<Pages.Laboratorios />} />
        <Route path="/npj" element={<Pages.NPJ />} />
        <Route path="/empresa" element={<Pages.Empresa />} />
        <Route path="/ambulatorio" element={<Pages.Ambulatorio />} />
        <Route path="/biblioteca" element={<Pages.Biblioteca />} />
        <Route path="/contato" element={<Pages.Contato />} />
        <Route path="/ouvidoria" element={<Pages.Ouvidoria />} />
        <Route path="/regulamentos" element={<Pages.Regulamentos />} />
        <Route path="/cpa" element={<Pages.Cpa />} />
        <Route path="/pdi" element={<Pages.Pdi />} />
        <Route path="/ppc" element={<Pages.Ppc />} />
        <Route path="/ntcc" element={<Pages.Ntcc />} />
        <Route path="/vestibular" element={<Pages.Vestibular />} />
        <Route path="/calendario" element={<Pages.Calendario />} />
        <Route path="/requerimento" element={<Pages.Requerimento />} />
        <Route path="/administracao" element={<Pages.Administracao />} />
        <Route path="/direito" element={<Pages.Direito />} />
        <Route path="/enfermagem" element={<Pages.Enfermagem />} />
        <Route path="/farmacia" element={<Pages.Farmacia />} />
        <Route path="/gastronomia" element={<Pages.Gastronomia />} />
        <Route path="/medicinavet" element={<Pages.MedicinaVet />} />
        <Route path="/odontologia" element={<Pages.Odontologia />} />
        <Route path="/radiologia" element={<Pages.Radiologia />} />
        <Route path="/servicosocial" element={<Pages.ServicoSocial />} />
        <Route path="/repositorio" element={<Pages.Repositorio />} />
        <Route path="/revista" element={<Pages.Revista />} />
        <Route path="/revista-edicao-1" element={<Pages.Edicao1 />} />
        <Route path="/revista-edicao-2" element={<Pages.Edicao2 />} />
        <Route path="/revista-edicao-3" element={<Pages.Edicao3 />} />
        <Route path="/revista-edicao-4" element={<Pages.Edicao4 />} />
        <Route path="/revista-edicao-5" element={<Pages.Edicao5 />} />
        <Route path="/revista-edicao-7" element={<Pages.Edicao7 />} />
        <Route path="/revista-edicao-8" element={<Pages.Edicao8 />} />
        <Route path="/revista-edicao-9" element={<Pages.Edicao9 />} />
        <Route path="/cursos-pos-graduacao" element={<Pages.PosGraduacao />} />
        <Route path="/direito-trabalho" element={<Pages.DireitoTrabalho />} />
        <Route path="/emergencia-aph" element={<Pages.EmergenciaAPH />} />
        <Route path="/enfermagem-cardiologia"
          element={<Pages.EnfermagemCardiologia />} />
        <Route path="/enfermagem-estomaterapia"
          element={<Pages.EnfermagemEstomaterapia />} />
        <Route path="/politica-social" element={<Pages.PoliticaSocial />} />
        <Route path="/saude-publica" element={<Pages.SaudePublica />} />
        <Route path="/Periodicos" element={<Pages.Periodicos />} />
        <Route path="/Recrutamento" element={<Pages.Recrutamento />} />
        <Route path="/Edital" element={<Pages.Edital />} />
        <Route path="/Ajuda" element={<Pages.Ajuda />} />
        <Route path="/CursosOferecidos" element={<Pages.CursosOferecidos />} />
      </Routes>
    </>
  );
}
