import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Ambulatorio() {
  return (
    <>
      <Header/>
      <div className='page-wrap'>
        <div className='page-title-wrap'>
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Ambulatório Escolar</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <p>
        A FACISA, com o intuito de aprimorar a qualidade do ensino aprendizado
        dos acadêmicos do curso de graduação de enfermagem e identificando as
        necessidades da população de Itamaraju em relação à saúde, implantou um
        Ambulatório Escola moderno, visando oferecer uma assistência
        diferenciada e de qualidade, composta por uma equipe multidisciplinar da
        saúde e a participação efetiva dos acadêmicos, prestando os seguintes
        serviços a população: Campanha de imunização (vacina), preventivo,
        aferição de pressão arterial, verificação de glicemia capilar e
        temperatura, realização de curativos, palestras educativas, consulta de
        enfermagem, administração de medicamentos e nebulização com receita
        medica. Essas atividades são prestadas a população no período vespertino
        (tarde). É de suma importância a participação da população neste
        processo, despertando na comunidade acadêmica o interesse teórico e
        cientifico proporcionando o desenvolvimento crítico e criativo com uma
        visão holística.
        </p>
      </div>
      <Footer/>

    </>
  );
}
