import React from 'react';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';

export default function EnfermagemCardiologia() {
  const marginSection = {marginTop: '1em'};

  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>PÓS GRADUAÇÃO EM ENFERMAGEM EM CARDIOLOGIA</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <p>
          Formar Especialistas para desempenharem suas atividades profissionais
          em unidades de emergência hospitalares e pré-hospitalares,
          laboratórios de hemodinâmica, unidades cardio intensivas e
          coronarianas, através de ações de abordagem coletiva e de abordagem
          clínica individual.
        </p>
        <h5 style={marginSection}>Carga Horária Total: 520h</h5>
        <h5 style={marginSection}>
          PÚBLICO ALVO: Profissionais que portem diploma de Graduação em
          Enfermagem e demais profissionais de nível superior da área de saúde.
        </h5>
        <h4 style={marginSection}>Grade Curricular</h4>
        <ol>
          <li>Metodologia do Ensino Superior</li>
          <li>Metodologia do Trabalho Científico</li>
          <li>
            Anatomofisiologia cardiopulmonar (sistema de condução e vasculatura
            coronariana)
          </li>
          <li>Cardiologia Clinica I – HAS e ICC</li>
          <li>Cardiologia Clínica II - SCA</li>
          <li>Farmacologia em Cardiologia</li>
          <li>Exames diagnósticos I – ECG</li>
          <li>
          Exames diagnósticos II – Holter, teste ergométrico, MAPA e Eco
          </li>
          <li>
          Hemodinâmica – Cardiologia intervencionista
          </li>
          <li>Assistência de enfermagem na Angioplastia</li>
          <li>Assistência de enfermagem na Cirurgia Cardíaca</li>
          <li>Novas tecnologias e dispositivos auxiliares</li>
          <li>Marcapasso e ressincronizadores</li>
          <li>Parada Cardiorrespiratória</li>
          <li>Cardiologia Pediátrica</li>
          <li>Ocorrências cardiovasculares na realidade do SAMU</li>
          <li>Prática em cardiologia</li>
        </ol>
      </div>
      <Footer />
    </>
  );
}
