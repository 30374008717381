import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const db = [
  {
    title: 'Pós Graduação em Direito do Trabalho',
    path: '/assets/cursos/direito-trabalho.jpg',
    link: '/direito-trabalho',
  },
  {
    title: 'Emergência e APH+',
    path: '/assets/cursos/emergencia-aph.jpg',
    link: '/emergencia-aph',
  },
  {
    title: 'Política Social e Gestão Pública',
    path: '/assets/cursos/politica-social.jpg',
    link: '/politica-social',
  },
  {
    title: 'Enfermagem em Cardiologia',
    path: '/assets/cursos/enfermagem-cardiologia.jpg',
    link: '/enfermagem-cardiologia',
  },
  {
    title: 'Saúde Pública+',
    path: '/assets/cursos/saude-publica.jpg',
    link: '/saude-publica',
  },
  {
    title: `Especialização em Enfermagem em
     Estomaterapia: Estomias, Feridas e Incontinências`,
    path: '/assets/cursos/enfermagem-estomaterapia.jpg',
    link: '/enfermagem-estomaterapia',
  },
];
export default function PosGraduacao() {
  return (
    <>
      <Header/>
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Cursos Pós-Graduação</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div className='pos-container file-card-container'
          style={{marginTop: '2em'}}>
          {db.map((pos) => (
            <a
              style={{textDecorationLine: 'none', color: 'black'}}
              className='file-card' key={pos.title}
              href={pos.link}
            >
              <img style={{width: '100%', marginBottom: '1em'}}src={pos.path}/>
              <h6 style={{textAlign: 'center'}}>{pos.title}</h6>
            </a>
          ))}

        </div>

      </div>
      <Footer/>
    </>
  );
}
