import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Localizacao.css';

export default function Localizacao() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Localização</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          Lugar de clima agradável, reserva uma das poucas áreas de mata
          atlântica ainda preservada. Vizinha ao Parque Nacional de Monte
          Pascoal, às margens da BR 101 e banhada pelo Rio Jucuruçu, a cidade de
          Itamaraju conta com uma população de cerca de 65.000 habitantes, boa
          infra-estrutura urbana, diversas opções de acomodação e todas as
          condições para estadia com conforto. Sua economia é bastante
          diversificada, no seu entorno concentra-se o maior rebanho de gado
          bovino da Bahia, além de apresentar uma agricultura bastante dinâmica.
          Seu desenvolvimento foi incrementado com a criação da FACISA - hoje
          comprovadamente uma das melhores opções de formação em nível superior
          do Extremo Sul da Bahia. Ideal para quem procura tranqüilidade,
          qualidade e excelência de ensino e de vida
        </p>
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Acesso</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3815.1296338708476!2d-39.5436971845123!3d-17.017310258752474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x735a52eaacb04a1%3A0x1112debf8134b799!2sFaculdade%20de%20Ci%C3%AAncias%20Sociais%20Aplicadas%20-%20Facisa!5e0!3m2!1spt-BR!2sbr!4v1666104813338!5m2!1spt-BR!2sbr"
          sandbox='allow-scripts allow-modal'
          loading='lazy'
          title='Mapa facisa.'
          className='mapa-facisa'>
        </iframe>
        <p>
          A cidade de Itamaraju fica localizada no Extremo Sul da Bahia, e é
          privilegiada por ser cortada pela BR 101, tendo ainda a FACISA às suas
          margens (Km 808). O acesso pelo lado norte fica por meio das cidades
          de Itabela (66 Km) e Eunápolis (94Km). Pelo sul, por Teixeira de
          Freitas (66Km). Pelo litoral, a cidade de Prado (52Km). Confira abaixo
          a tabela de distâncias para as principais cidades: Salvador – 744 Km
          Itabuna – 307 Km Ilhéus – 327 Km Porto Seguro – 156 Km Vitória – 428
          Km São Paulo – 1.308 Kmr Rio de Janeiro – 942 Km Brasília – 1.312 Km
          Belo Horizonte – 1.312 Km
        </p>
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Praias</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          A cidade de Itamaraju é privilegiada por ficar muito próxima a vários
          paraísos tropicais. Confira a distância e rota a alguns deles: Prado -
          52 Km Alcobaça - 75 Km Cumuruxatiba - 74 Km Caravelas - 132 Km Mucuri
          - 160 Km Porto Seguro – 156 Km Santa Cruz Cabrália - 177 Km Trancoso -
          121 Km Itacaré - 404 Km.
        </p>
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Acesso à FACISA</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
          A FACISA fica a 3 Km do Centro Comercial de Itamaraju. Existem várias
          linhas de ônibus urbano que cobrem esse trajeto. Confira na imagem ao
          lado o trajeto onde o ponto A é a FACISA e o ponto B é o Centro:
          Trajeto FACISA – Centro
        </p>
      </div>
      <Footer />
    </>
  );
}
