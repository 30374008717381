import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

export default function Odontologia() {
  return (
    <>
      <Header/>
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Odontologia</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <div className='page-image-container'>
          <img src='/assets/cursos/odonto.webp'/>
        </div>
        <p>
        O curso de odontologia da FACISA foi criado com o objetivo de formar um
        profissional - cidadão competente. Norteado dentro de um conceito de
        qualidade de constante aprendizado, os nossos futuros cirurgiões
        dentistas não se restringirão aos aspectos apenas das etiologias, mas,
        além da formação técnico-científica estarão aptos a exercer a profissão
        conscientes do seu agir profissional, na promoção de saúde fundamentada
        nos preceitos éticos, morais, voltadas para a realidade da população
        brasileira.
        </p>
      </div>
      <Footer/>

    </>
  );
}
