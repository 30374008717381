import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Laboratorios() {
  return (
    <>
      <Header/>
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Laboratório de Informática I e II</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
        Os laboratórios de informática da FACISA, é o meio de acesso a
        equipamentos de informática pelos discentes, docentes e o administrativo
        atendendo às necessidades institucionais, inclusive equipados para aula
        se assim o docente necessitar disponibilizando equipamentos de
        qualidade, proporcionando conforto, estabilidade de velocidade de acesso
        à internet, à rede sem fio e à adequação do espaço físico, possui
        hardware e software atualizados e passa por avaliação periódica de sua
        adequação, qualidade e pertinência. Horário de funcionamento: de segunda
        a sexta-feira das 14:00h às 22:00h.
        </p>
      </div>
      <Footer/>
    </>
  );
}
