import React from 'react';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';

export default function EmergenciaAPH() {
  const marginSection = {marginTop: '1em'};

  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>PÓS GRADUAÇÃO EM EMERGÊNCIA E APH</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>
        <p>
          Formar Especialistas para desempenharem suas atividades profissionais
          em unidades de emergência hospitalares e pré-hospitalares, através de
          ações de abordagem coletiva e de abordagem clínica individual.
        </p>
        <h5 style={marginSection}>Carga Horária Total: 480h</h5>
        <h5 style={marginSection}>
          PÚBLICO ALVO: Profissionais que portem diploma de Graduação em
          Enfermagem e demais profissionais de nível superior da área de saúde.
        </h5>
        <h4 style={marginSection}>Grade Curricular</h4>
        <ol>
          <li>Metodologia do Ensino Superior</li>
          <li>Metodologia do Trabalho Científico</li>
          <li>Competências profissionais no Mundo Moderno</li>
          <li>Atendimento pré-hospitalar e Emergências traumáticas</li>
          <li>Suporte básico de vida</li>
          <li>Emergências cardiológicas</li>
          <li>Emergências obstétricas</li>
          <li>Emergências psiquiátricas</li>
          <li>Bioética em urgência e emergência</li>
          <li>Emergências pediátricas</li>
          <li>Emergências neurológicas</li>
          <li>Emergências clínicas</li>
          <li>Vias aéreas de emergência e ventilação mecânica</li>
          <li>
            Atendimento a múltiplas vítimas e Gestão do atendimento de
            emergência
          </li>
          <li>Resgate em altura, terrestre, aquático e RCP</li>
          <li>Trabalho de Conclusão de Curso – TCC</li>
        </ol>
      </div>
      <Footer />
    </>
  );
}
