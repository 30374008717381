import React from 'react';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import './../../../filecard.css';

export default function Edicao5() {
  return (
    <>
      <Header />
      <div className="page-wrap">
        <div className="page-title-wrap">
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Revista Acadêmica - 5º Edição</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <div className="file-card-container single-edition">

          <div className="file-card">
            <h1 className="file-card-title center-text">
            5º Edição </h1>
            <div className="file-card-divison"></div>
            <a href="/assets/revista/5_edicao.pdf"
              className="file-card-link">
              <img
                src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
                className="pdf-img"
              />
              <p>Abrir o PDF</p>
            </a>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}
