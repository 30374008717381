import Ambulatorio from './Ambulatorio/Ambulatorio';
import Biblioteca from './Biblioteca/Biblioteca';
import Calendario from './Calendario/Calendario';
import Ceam from './Ceam/Ceam';
import Cpa from './Cpa/Cpa';
import Administracao from './Cursos/Administracao/Administracao';
import Direito from './Cursos/Direito/Direito';
import Enfermagem from './Cursos/Enfermagem/Enfermagem';
import Farmacia from './Cursos/Farmacia/Farmacia';
import Gastronomia from './Cursos/Gastronomia/Gastronomia';
import MedicinaVet from './Cursos/MedicinaVet/Medicinavet';
import Odontologia from './Cursos/Odontologia/Odontologia';
import Radiologia from './Cursos/Radiologia/Radiologia';
import ServicoSocial from './Cursos/ServicoSocial/Servico_social';
import Empresa from './Empresa/Empresa';
import Estrutura from './Estrutura/Estrutura';
import Home from './Home/Home';
import Institucional from './Institucional/Institucional';
import Laboratorios from './Laboratorios/Laboratorios';
import Localizacao from './Localizacao/Localizacao';
import Nap from './Nap/Nap';
import NPJ from './Npj/Npj';
import Ntcc from './Ntcc/Ntcc';
import Ouvidoria from './Ouvidoria/Ouvidoria';
import Pdi from './Pdi/Pdi';
import Ppc from './Ppc/Ppc';
import Regulamentos from './Regulamentos/Regulamentos';
import Requerimento from './Requerimento/Requerimento';
import Secretaria from './Secretaria/Secretaria';
import Visao from './VisaoMissao/Visao';
import Revista from './Revista/Revista';
import Edicao1 from './Revista/Edições/Edicao1';
import Edicao2 from './Revista/Edições/Edicao2';
import Edicao3 from './Revista/Edições/Edicao3';
import Edicao4 from './Revista/Edições/Edicao4';
import Edicao5 from './Revista/Edições/Edicao5';
import Edicao7 from './Revista/Edições/Edicao7';
import Edicao8 from './Revista/Edições/Edicao8';
import Edicao9 from './Revista/Edições/Edicao9';
import PosGraduacao from './Pos-Graduacao/PosGraduacao';
import DireitoTrabalho from './Pos-Graduacao/Cursos/DireitoTrabalho';
import EmergenciaAPH from
  './Pos-Graduacao/Cursos/EmergenciaAPH';
import EnfermagemCardiologia from
  './Pos-Graduacao/Cursos/EnfermagemCardiologia';
import EnfermagemEstomaterapia from
  './Pos-Graduacao/Cursos/EnfermagemEstomaterapia';
import PoliticaSocial from './Pos-Graduacao/Cursos/PoliticaSocial';
import SaudePublica from './Pos-Graduacao/Cursos/SaudePublica';
import Repositorio from './Repositorio-Monografias/Repositorio';
import Periodicos from './Periodicos/Periodicos';
import Recrutamento from './Recrutamento/Recrutamento';
import Edital from './Edital/Edital';
import CursosOferecidos from './CursosOferecidos/Oferecidos';
import Ajuda from './Ajuda/Ajuda';

export default {
  Ambulatorio,
  Biblioteca,
  Calendario,
  Ceam,
  Cpa,
  Administracao,
  Direito,
  Enfermagem,
  Farmacia,
  Gastronomia,
  MedicinaVet,
  Odontologia,
  Radiologia,
  ServicoSocial,
  Empresa,
  Estrutura,
  Home,
  Institucional,
  Laboratorios,
  Localizacao,
  Nap,
  NPJ,
  Ntcc,
  Ouvidoria,
  Pdi,
  Ppc,
  Regulamentos,
  Requerimento,
  Secretaria,
  Visao,
  Revista,
  Edicao1,
  Edicao2,
  Edicao3,
  Edicao4,
  Edicao5,
  Edicao7,
  Edicao8,
  Edicao9,
  PosGraduacao,
  DireitoTrabalho,
  EmergenciaAPH,
  EnfermagemCardiologia,
  EnfermagemEstomaterapia,
  PoliticaSocial,
  SaudePublica,
  Repositorio,
  Periodicos,
  Recrutamento,
  Edital,
  CursosOferecidos,
  Ajuda,
};
