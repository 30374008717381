import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Empresa() {
  return (
    <>
      <Header/>
      <div className='page-wrap'>
        <div className='page-title-wrap'>
          <img src="https://i.imgur.com/8n3nyYR.png" />
          <h1>Empresa Junior</h1>
          <img src="https://i.imgur.com/8n3nyYR.png" />
        </div>

        <p>
        Acredita no poder de transformação da sociedade através de jovens
        comprometidos com a causa e com vontade de mudar a realidade. Esses são
        os ingredientes que levam a Empresa Júnior FACISA a realizar projetos de
        consultoria. Formada e gerida por estudantes do curso de Administração
        da Faculdade de Ciências Sociais Aplicadas, oferecemos um serviço
        personalizado e de qualidade nas áreas de Marketing, Financeiro, Novo
        Negócio e Organizacional.
        </p>
        <p> Uma organização voltada para o desenvolvimento
        de jovens empreendedores, através de projetos de consultoria que
        solucionem de maneira resolutiva e personalizada os objetivos dos nossos
        clientes. Nossos projetos possuem foco em entregar resultado para os
        nossos clientes, mediante a formação de estudantes qualificados que
        possam mudar o cenário socioeconômico da região de atuação e do país.
        Serviços ofertados pela Empresa Júnior FACISA; Financeiro (Estruturação
        financeira, Análise financeira e Valuation); Novo negócio (Plano de
        negócios e Estudo de viabilidade); Marketing (Plano de marketing,
        Pesquisa de mercado e Marketing estratégico); Organizacional (Estrutura
        organizacional, Padronização de processos, Pesquisa de clima e Avaliação
        de desempenho).</p>
        <p> Quais as áreas de atuação? Nós da Empresa Júnior
        Administração FACISA estamos entregando projetos de consultoria para
        nossos clientes, tendo sempre em mente: Projetos personalizados Projetos
        que gerem resultado Consultores jovens e dispostos a dar o seu melhor
        Esses três princípios, trazem os nossos membros para o foco desejado, e
        apenas através dos projetos adquiridos por nossos clientes poderemos
        impactar na sociedade através do desenvolvimento de jovens
        empreendedores. Consultoria em Financeiro, Consultoria em Marketing,
        Consultoria Organizacional, Consultoria de Novos Negócios, Estruturação
        Financeira, Análise Financeira, Plano de Marketing, Pesquisa de Mercado,
        Estruturação Organizacional, Padronização de Processos, Estudo de
        Viabilidade, Plano de Negócios.</p>
      </div>
      <Footer/>
    </>
  );
}
